<template>
  <div class="row marketing-page_wrapper">
    <div class="col-lg-12 d-flex justify-content-end mb-h">
                  <button
                :disabled="isShowingDateRangeLimitExceededError"
                v-tooltip="`${$t('marketing.createExportButtonInfo')}`"
                class="btn btn-info "
                type="button"
                @click="createSettingsForNewExportAndGoToExports"
              >
                {{ $t('marketing.createExportButtonCaption') }}
                <!--                <info class="" :content="$t('marketing.createExportButtonInfo')"/>-->
                <!--                <info class=""/>-->
              </button>
    </div>

    <div class="col-lg-12 mb-h">
      <div class="row">
        <div class="col-lg-3 pr-0" style="z-index:9996">
          <div class="input-group input-group-sm">
                          <span class="input-group-addon filters__field-icon">
                  <i class="fa fa-building-o"
                     :class="{ 'text-primary': filters.company !== 'All companys' }"></i>
                </span>
            <MultiselectWrapperForUseInFilters :z-index="999998">
              <multiselect
                :disabled="isSelectedDefaultLocationForRequests"
                v-tooltip.top-center="{ content: filters.company.name }"
                class="filters-in-visitors-company-multiselect"
                :multiple="false"
                :allowEmpty="true"
                v-model="filters.company"
                :options="companys || []"
                label="name"
                trackBy="name"
                @input="changeCompany"
                :closeOnSelect="true"
                :placeholder="$t('marketing.selectCompanyPlaceholder')"
                :selectLabel="$t('general.multiSelectPressToSelect')"
                :deselectLabel="$t('general.multiSelectDeselectLabel')"
                :selectedLabel="$t('general.multiSelectSelected')"
              >
                <!--                    <template slot="singleLabel" slot-scope="props"><span-->
                <!--                      class="option__title">{{ cutOffLongTextForCompanyMultiSelect(props.option.name) }}</span>-->
                <!--                    </template>-->
              </multiselect>
            </MultiselectWrapperForUseInFilters>

          </div>
        </div>
<!--        <div class="col-lg-3" v-if="filters.company === 'All companys'">-->
<!--          <div class="input-group input-group-sm">-->
<!--                                          <span class="input-group-addon filters__field-icon">-->
<!--                  <i class="fa fa-map-marker"-->
<!--                     :class="{ 'text-primary': filters.location !== 'All locations' }"></i>-->
<!--                </span>-->
<!--            <MultiselectWrapperForUseInFilters>-->
<!--              <multiselect-->
<!--                v-tooltip.top-center="{ content: filters.location.name,                           popperOptions: {-->
<!--                            modifiers: {-->
<!--                              preventOverflow: {-->
<!--                                boundariesElement: 'window'-->
<!--                              }-->
<!--                            }-->
<!--                          } }"-->
<!--                class="filters-in-visitors-location-multiselect"-->
<!--                :multiple="false"-->
<!--                :allowEmpty="true"-->
<!--                v-model="filters.location"-->
<!--                :options="locationsList || []"-->
<!--                label="name"-->
<!--                trackBy="name"-->
<!--                @input="changeLocation"-->
<!--                :closeOnSelect="true"-->
<!--                :placeholder="$t('marketing.selectLocationPlaceholder')"-->
<!--                :selectLabel="$t('general.multiSelectPressToSelect')"-->
<!--                :deselectLabel="$t('general.multiSelectDeselectLabel')"-->
<!--                :selectedLabel="$t('general.multiSelectSelected')"-->
<!--              >-->
<!--                &lt;!&ndash;                    <template slot="singleLabel" slot-scope="props"><span&ndash;&gt;-->
<!--                &lt;!&ndash;                      class="option__title">{{ cutOffLongTextForLocationMultiSelect(props.option.name) }}</span>&ndash;&gt;-->
<!--                &lt;!&ndash;                    </template>&ndash;&gt;-->
<!--              </multiselect>-->
<!--            </MultiselectWrapperForUseInFilters>-->


<!--          </div>-->
                      <div
              class="col-lg-3  input-group-for-location-select-in-AnalyticsVistiors--filters pr-0 " style="z-index:99997" v-if="filters.company === 'All companys'">


                                                                   <span
                                                                     class="input-group-addon filters__field-icon">
                  <i class="fa fa-map-marker"
                     :class="{ 'text-primary': filters.location !== 'All locations' }"></i>
                </span>
              <SelectComponentV3ForUseInLocationSelectors

                v-if="showLocationFilter"
                no-wrap-selected-option
                :show-search-field-into-items-list="!isSelectedDefaultLocationForRequests || (isSelectedDefaultLocationForRequests && $store.getters.getDefaultWithChildsForRequests())"
                class="select-location-in-AnalyticsVistiors--filters"
                enable-max-option-width
                small-size
                :no-clear-selected-option-when-click-on-it="isSelectedDefaultLocationForRequests"
                :show-delete-button="!isSelectedDefaultLocationForRequests"
                v-model="locationInFiltersData"
                track-by="name"
                option-id-name="id"
                :no-options-found="$t('general.noResultForSearch')"
                :async-function="requestLocations.bind(this)"
                :placeholder="$t('portalStats.selectLocation')"
                @select="selectLocationInFilters"
              >
                <template #dropdown-start>
                  <div class="ml-h mt-1">
                    <Switch-component
                      v-if="!isSelectedDefaultLocationForRequests || (isSelectedDefaultLocationForRequests && $store.getters.getDefaultWithChildsForRequests())"
                      @input="selectWithChildsInFilters"
                      :label="$t('general.withChild')"
                      v-model="filters.with_childs"
                      class=""
                    />
                  </div>
                </template>
              </SelectComponentV3ForUseInLocationSelectors>


            </div>
<!--        </div>-->
        <div class="col-lg-3 pr-0"  style="z-index:99997" v-else>
          <div class="input-group input-group-sm">
                                          <span class="input-group-addon filters__field-icon">
                  <i class="fa fa-map-marker"
                     :class="{ 'text-primary': filters.location !== 'All locations' }"></i>
                </span>
            <MultiselectWrapperForUseInFilters>
              <multiselect
                v-tooltip.top-center="{ content: filters.location.name }"
                class="filters-in-visitors-location-multiselect"
                :multiple="false"
                :allowEmpty="false"
                v-model="filters.location"
                :options="locationsObjbyIds(filters.company.locations)"
                label="name"
                trackBy="name"
                @input="changeLocationIfCompanySelected"
                :closeOnSelect="true"
                :placeholder="$t('marketing.selectLocationPlaceholder')"
                :selectLabel="$t('general.multiSelectPressToSelect')"
                :deselectLabel="$t('general.multiSelectDeselectLabel')"
                :selectedLabel="$t('general.multiSelectSelected')"
              >
                <!--                    <template slot="singleLabel" slot-scope="props"><span-->
                <!--                      class="option__title">{{ cutOffLongTextForLocationMultiSelect(props.option.name) }}</span>-->
                <!--                    </template>-->
              </multiselect>
            </MultiselectWrapperForUseInFilters>


          </div>
        </div>
        <div class="col-lg-3 pr-0" style="z-index:99996">
          <div class="input-group ">
                                  <span class="input-group-addon filters__field-icon ">
                                          <img
                                            class="img img-responsive cpe-icon"
                                            src="/static/img/router-icon.svg"
                                            alt
                                          />
                      </span>
<!--            <MultiselectWrapperWhenMultiselectForUseInFilters z-index="99996" :selectedOneHeight="filters.cpes.length > 1 ? false : true">-->
<!--              <multiselect-->
<!--                class="filters-in-visitors-cpes-multiselect"-->
<!--                :multiple="true"-->
<!--                v-model="filters.cpes"-->
<!--                :options="cpesListWithNames || []"-->
<!--                label="name"-->
<!--                trackBy="id"-->
<!--                @close="changeCPEs"-->
<!--                @input="changeCPEsWithDelay"-->
<!--                :closeOnSelect="false"-->
<!--                :placeholder="$t('radar.selectPlaceholder')"-->
<!--                selectLabel="+"-->
<!--                deselectLabel="-"-->
<!--                :disabled="isDisableCpes"-->
<!--                :class="{ 'multiselect-disabled': isDisableCpes }"-->
<!--                :selectLabel="$t('general.multiSelectPressToSelect')"-->
<!--                :deselectLabel="$t('general.multiSelectDeselectLabel')"-->
<!--                :selectedLabel="$t('general.multiSelectSelected')"-->
<!--              >-->
<!--                <template slot="option" slot-scope="props">-->
<!--                  <div class="option__desc" v-if="!showDescriptionInCpesFilter">-->
<!--                        <span-->
<!--                          class="badge-cpe badge-status ml-h tooltip-text"-->
<!--                          :class="{-->
<!--                            'badge-status__wifi-target-enabled': checkCpeForWiFiTargetEnabled(props),-->
<!--                            'badge-status__wifi-target-disabled': !checkCpeForWiFiTargetEnabled(props)-->
<!--                          }"-->
<!--                          v-tooltip.top-center="{-->
<!--                            content: `<ul class='list-unstyled text-left m-0'>-->
<!--                                          <li><small><b>id</b>: ${props.option.id}</small></li>-->
<!--                                          <li><small><b>mac</b>: ${props.option.state.network.macaddr}</small></li>-->
<!--                                          <li><small><b>ip</b>: ${props.option.state.network.ipaddr}</small></li>-->
<!--                                        </ul>`-->
<!--                        ,-->
<!--                          popperOptions: {-->
<!--                            modifiers: {-->
<!--                              preventOverflow: {-->
<!--                                boundariesElement: 'window'-->
<!--                              }-->
<!--                            }-->
<!--                          }-->
<!--                        }"-->
<!--                        >-->
<!--                          <span v-if="props.option.name">{{ props.option.name }}</span>-->
<!--                          &lt;!&ndash;                          <template v-if="props.option.name">&ndash;&gt;-->
<!--                          &lt;!&ndash;                            <span v-if="props.option.name && !showDescriptionInCpesFilter">{{ props.option.name }}</span>&ndash;&gt;-->
<!--                          &lt;!&ndash;                            <template v-if="props.option.name && showDescriptionInCpesFilter">&ndash;&gt;-->
<!--                          &lt;!&ndash;                              <span v-if="!props.option.description">{{ props.option.name.split(':')[0] }}</span>&ndash;&gt;-->
<!--                          &lt;!&ndash;                              <span v-if="props.option.description" v-tooltip.right="{ content: props.option.description }">{{ props.option.name.split(':')[0]}}: {{ cutOffLongTextForCPEDescription(props.option.description)}}</span>&ndash;&gt;-->
<!--                          &lt;!&ndash;                            </template>&ndash;&gt;-->
<!--                          &lt;!&ndash;                          </template>&ndash;&gt;-->

<!--                          <span class="text-muted" v-else>{{ $t('misc.no_name') }}</span>-->
<!--                        </span>-->
<!--                  </div>-->
<!--                  <div class="option__desc" v-if="showDescriptionInCpesFilter">-->
<!--                        <span-->
<!--                          class="badge-cpe badge-status ml-h tooltip-text"-->
<!--                          :class="{-->
<!--                            'badge-status__connected': props.option.connected,-->
<!--                            'badge-status__disconnected': !props.option.connected-->
<!--                          }"-->
<!--                          v-tooltip="{-->
<!--                            content: `<ul class='list-unstyled text-left m-0'>-->
<!--                                          <li><small><b>id</b>: ${props.option.id}</small></li>-->
<!--                                          <li><small><b>mac</b>: ${props.option.state.network.macaddr}</small></li>-->
<!--                                          <li><small><b>ip</b>: ${props.option.state.network.ipaddr}</small></li>-->
<!--                                          <li><small>${$t('general.description')}: <b></b> ${-->
<!--                              props.option.description ? props.option.description : $t('general.no').toLowerCase()-->
<!--                            }</small></li>-->
<!--                                        </ul>`-->
<!--                          }"-->
<!--                        >-->
<!--                          &lt;!&ndash;                          <span v-if="props.option.name">{{ props.option.name }}</span>&ndash;&gt;-->
<!--                          <template v-if="props.option.name">-->
<!--                            <span v-if="!props.option.description">-->
<!--                              {{ props.option.name.split(charactersForCpeListSeparateNameFromDescription)[0] }}-->
<!--                            </span>-->
<!--                            <span v-if="props.option.description">-->
<!--                              {{ props.option.name.split(charactersForCpeListSeparateNameFromDescription)[0] }}:-->
<!--                              {{ cutOffLongTextForCPEDescription(props.option.description) }}-->
<!--                            </span>-->
<!--                          </template>-->

<!--                          <span class="text-muted" v-else>{{ $t('misc.no_name') }}</span>-->
<!--                        </span>-->
<!--                  </div>-->
<!--                </template>-->
<!--              </multiselect>-->

<!--            </MultiselectWrapperWhenMultiselectForUseInFilters>-->

                        <MultiselectWrapperWhenMultiselectForUseInFilters z-index="99996" :selectedOneHeight="filters.cpes.length > 1 ? false : true">
              <multiselect
                class="filters-in-visitors-cpes-multiselect"
                :multiple="true"
                v-model="filters.cpes"
                :options="cpesListWithNames || []"
                label="name"
                trackBy="id"
                @open="openCpesSelector"
                @close="closeCpesSelector"
                @remove="removeCPEsWithDelay"
                @input="selectCpeInCpesSelector"
                :closeOnSelect="false"
                :placeholder="$t('radar.selectPlaceholder')"
                selectLabel="+"
                deselectLabel="-"
                :disabled="isDisableCpes"
                :class="{ 'multiselect-disabled': isDisableCpes }"
                :selectLabel="$t('general.multiSelectPressToSelect')"
                :deselectLabel="$t('general.multiSelectDeselectLabel')"
                :selectedLabel="$t('general.multiSelectSelected')"
              >
                <template slot="option" slot-scope="props">
                  <div class="option__desc" v-if="!showDescriptionInCpesFilter">
                        <span
                          class="badge-cpe badge-status ml-h tooltip-text"
                          :class="{
                            'badge-status__wifi-target-enabled': checkCpeForWiFiTargetEnabled(props),
                            'badge-status__wifi-target-disabled': !checkCpeForWiFiTargetEnabled(props)
                          }"
                          v-tooltip.top-center="{
                            content: `<ul class='list-unstyled text-left m-0'>
                                          <li><small><b>id</b>: ${props.option.id}</small></li>
                                          <li><small><b>mac</b>: ${props.option.state.network.macaddr}</small></li>
                                          <li><small><b>ip</b>: ${props.option.state.network.ipaddr}</small></li>
                                        </ul>`
                        ,
                          popperOptions: {
                            modifiers: {
                              preventOverflow: {
                                boundariesElement: 'window'
                              }
                            }
                          }
                        }"
                        >
                          <span v-if="props.option.name">{{ props.option.name }}</span>
                          <!--                          <template v-if="props.option.name">-->
                          <!--                            <span v-if="props.option.name && !showDescriptionInCpesFilter">{{ props.option.name }}</span>-->
                          <!--                            <template v-if="props.option.name && showDescriptionInCpesFilter">-->
                          <!--                              <span v-if="!props.option.description">{{ props.option.name.split(':')[0] }}</span>-->
                          <!--                              <span v-if="props.option.description" v-tooltip.right="{ content: props.option.description }">{{ props.option.name.split(':')[0]}}: {{ cutOffLongTextForCPEDescription(props.option.description)}}</span>-->
                          <!--                            </template>-->
                          <!--                          </template>-->

                          <span class="text-muted" v-else>{{ $t('misc.no_name') }}</span>
                        </span>
                  </div>
                  <div class="option__desc" v-if="showDescriptionInCpesFilter">
                        <span
                          class="badge-cpe badge-status ml-h tooltip-text"
                          :class="{
                            'badge-status__connected': props.option.connected,
                            'badge-status__disconnected': !props.option.connected
                          }"
                          v-tooltip="{
                            content: `<ul class='list-unstyled text-left m-0'>
                                          <li><small><b>id</b>: ${props.option.id}</small></li>
                                          <li><small><b>mac</b>: ${props.option.state.network.macaddr}</small></li>
                                          <li><small><b>ip</b>: ${props.option.state.network.ipaddr}</small></li>
                                          <li><small>${$t('general.description')}: <b></b> ${
                              props.option.description ? props.option.description : $t('general.no').toLowerCase()
                            }</small></li>
                                        </ul>`
                          }"
                        >
                          <!--                          <span v-if="props.option.name">{{ props.option.name }}</span>-->
                          <template v-if="props.option.name">
                            <span v-if="!props.option.description">
                              {{ props.option.name.split(charactersForCpeListSeparateNameFromDescription)[0] }}
                            </span>
                            <span v-if="props.option.description">
                              {{ props.option.name.split(charactersForCpeListSeparateNameFromDescription)[0] }}:
                              {{ cutOffLongTextForCPEDescription(props.option.description) }}
                            </span>
                          </template>

                          <span class="text-muted" v-else>{{ $t('misc.no_name') }}</span>
                        </span>
                  </div>
                </template>
              </multiselect>

            </MultiselectWrapperWhenMultiselectForUseInFilters>


          </div>
<!--                                    <div class="d-flex justify-content-between">-->
<!--                  &lt;!&ndash;                  <label>&ndash;&gt;-->
<!--                  &lt;!&ndash;                    {{ $t('marketing.radar') }}&ndash;&gt;-->
<!--                  &lt;!&ndash;                    <span v-if="!filters.cpes.length" class="text-muted">({{ $t('marketing.allCpes') }})</span>&ndash;&gt;-->
<!--                  &lt;!&ndash;                  </label>&ndash;&gt;-->
<!--                  <div class="w-100" style="text-align: right;">-->
<!--                    <a-->
<!--                      href="#"-->
<!--                      class="ml-q filter-in-visitors-cpes-show-description"-->
<!--                      @click.prevent="toggleShowingDescriptionInCpesFilter"-->
<!--                    >-->
<!--                      {{-->
<!--                        showDescriptionInCpesFilter ? $t('marketing.hideDescription') : $t('marketing.showDescription')-->
<!--                      }}-->
<!--                    </a>-->
<!--                  </div>-->
<!--                </div>-->

                                    <div class="d-flex justify-content-between">
                  <!--                  <label>-->
                  <!--                    {{ $t('marketing.radar') }}-->
                  <!--                    <span v-if="!filters.cpes.length" class="text-muted">({{ $t('marketing.allCpes') }})</span>-->
                  <!--                  </label>-->
                                      <div class="w-100" style="text-align: left;">
                                        <span v-if="!filters.cpes.length" class="text-muted">({{ $t('marketing.allCpesSelected') }})</span>
                                        <span @click.prevent.stop="removeAllFromCpesSelector"
                                              v-else class="text-muted">
                                          <a
                                            href="#" class="ml-q mb-1"
                                            @click.prevent="clearSelectedLocation"><span>{{ $t('general.clear') }}</span></a>
                                        </span>

                                      </div>
                </div>

<!--           <span  v-if="!filters.cpes.length" class="text-muted">({{ $t('marketing.allCpes') }})</span>-->
        </div>

      </div>
      <div class="row mt-h" style="margin-bottom: -15px" >
                  <!--          worktime filters-->
          <div class="col-lg-12 mt-q  mb-0"  style="position: relative">
            <div class=" col-lg-12 mt-q mb-0" style="position: relative; top: -20px;">
              <div class="row mb-0">
                <div class="col-lg-12 col-sm-12 pl-0 pr-0 d-flex justify-content-start">
                  <div @click="toggleWorkTimeFiltersShowing" role="button">
                    <transition name="fadeforworktimefiltersinvisitors">
                      <i v-if="workTimeFilters.workTimeFiltersBlockShowing" class="icon-arrow-down"></i>
                      <i v-else class="icon-arrow-right"></i>
                    </transition>
                    &nbsp;
                    <span class="text-muted">{{ $t('marketing.workTimeBlockCaption') }} &nbsp;</span>
                    <info :content="$t('marketing.workTimeHint')" />
                    &nbsp;
                    <!--                    <transition name="fadeforworktimefiltersinvisitors">-->
                    <!--                      <i v-if="workTimeFilters.workTimeFiltersBlockShowing"-->
                    <!--                         class="icon-arrow-down"></i>-->
                    <!--                      <i v-else class="icon-arrow-left"></i>-->
                    <!--                    </transition>-->
                    &nbsp;
                    <transition name="fadeforworktimefiltersinvisitors">
                      <span
                        class="badge badge-light badge-success badge-wortime-wilter-enabled"
                        v-if="isWorkTimeFiltersEnabled"
                      >
                        {{ $t('marketing.workTimeFilterEnabled') }} &nbsp;
                      </span>
                    </transition>
                    &nbsp;
                  </div>
                </div>
              </div>
              <transition name="fadeforworktimefiltersinvisitors">
                <div class="row" v-show="workTimeFilters.workTimeFiltersBlockShowing">
                  <div class=" pl-0 col-lg-12 col-sm-12 d-flex justify-content-start align-items-start mt-h flex-wrap">
                    <!--    worktime enable switcher  -->
                    <div class="form-group mr-1 d-flex flex-column justify-content-end align-items-start mb-0">
                      <label class="mb-0" for="worktime-enable-switcher">{{ $t('general.enable') }}</label>
                      <div style="position:relative; top:-8px !important;" class="worktime-enable-switcher-wrapper-in-visitors-worktime-filters">
                        <Switch-component
                          class="worktime-enable-switcher-in-visitors-worktime-filters"
                          id="worktime-enable-switcher"
                          v-model="workTimeFilters.workTimeFiltersEnabled"
                          @change="handleWorktimeFilterEnableSwitcherSwitch"
                        />
                      </div>
                    </div>
                    <!--                    start-work-time start work time-->
                    <transition name="fadeforworktimefiltersinvisitors">
                      <div
                        v-if="isWorkTimeFiltersEnabled"
                        class="form-group mr-1 d-flex flex-column justify-content-end align-items-start worktime-filters-in-visitors-time-enter-fileds-wrappers"
                      >
                        <label class="mb-0" for="start-work-time">
                          {{ $t('marketing.workTimeStart') }}
                          <info :content="$t('marketing.workTimeStartHint')" />
                        </label>
                        <input
                          type="text"
                          name="start-work-time"
                          id="start-work-time"
                          :placeholder="$t('marketing.workTimeStartPlaceholder')"
                          class="form-control form-control-sm"
                          v-validate="'required|hoursMinutesForVisitorsFilters'"
                          data-vv-validate-on="none"
                          :class="{ input: true, 'is-danger': errors.has('start-work-time') }"
                          v-model="workTimeFilters.startWorkTime"
                          :data-vv-as="$t('marketing.workTimeStart')"
                          @input="inputStartWorkTimeFieldInput"
                        />
                        <span v-show="errors.has('start-work-time')" class="help is-danger">
                          {{ errors.first('start-work-time') }}
                        </span>
                      </div>
                    </transition>
                    <transition name="fadeforworktimefiltersinvisitors">
                      <!--                    stop-work-time stop work time-->
                      <div
                        v-if="isWorkTimeFiltersEnabled"
                        class="form-group mr-1 d-flex flex-column justify-content-end align-items-start worktime-filters-in-visitors-time-enter-fileds-wrappers"
                      >
                        <label class="mb-0" for="stop-work-time">
                          {{ $t('marketing.workTimeStop') }}
                          <info :content="$t('marketing.workTimeStopHint')" />
                        </label>
                        <input
                          type="text"
                          name="stop-work-time"
                          id="stop-work-time"
                          :placeholder="$t('marketing.workTimeStopPlaceholder')"
                          class="form-control  form-control-sm"
                          v-validate="'required|hoursMinutesForVisitorsFilters'"
                          data-vv-validate-on="none"
                          :class="{ input: true, 'is-danger': errors.has('stop-work-time') }"
                          v-model="workTimeFilters.stopWorkTime"
                          :data-vv-as="$t('marketing.workTimeStop')"
                          @input="inputStopWorkTimeFieldInput"
                        />
                        <span v-show="errors.has('stop-work-time')" class="help is-danger">
                          {{ errors.first('stop-work-time') }}
                        </span>
                      </div>
                    </transition>
                    <transition name="fadeforworktimefiltersinvisitors">
                      <!--                    timezone-->
                      <div
                        v-if="isWorkTimeFiltersEnabled"
                        class="form-group mr-1 d-flex flex-column justify-content-end align-items-start worktime-filters-in-visitors-timezone-enter-multiselect-wrapper timezone-selector-in-AnalyticsVistiors"
                      >
                        <label class="mb-0" for="tz">{{ $t('marketing.workTimeTimezone') }}</label>
                        <MultiselectWrapperForUseInFilters>
                        <multiselect
                          v-tooltip.top-center="{ content: tooltipForWorkTimeTimezoneSelectedInMultiselect,                           popperOptions: {
                            modifiers: {
                              preventOverflow: {
                                boundariesElement: 'window'
                              }
                            }
                          } }"
                          id="tz"
                          v-model="workTimeTimezoneSelectedInMultiselect"
                          @remove="handleTimezoneWorkTimeSelectorRemove"
                          @select="
                            (selectedOpt) => {
                              // тут делаем чтобы для москвы выбиралось Europe/Moscow
                              if (selectedOpt.value === 'Moscow Standard Time') {
                                workTimeFilters.time_zone = selectedOpt.utc[1];
                              } else {
                                workTimeFilters.time_zone = selectedOpt.utc[0];
                              }
                              this.handleTimezoneWorkTimeSelectorSelect();
                            }
                          "
                          :options="timeZonesOptions"
                          class="input worktime-filters-in-visitors-timezone-enter-multiselect"
                          :class="{ 'is-danger': workTimeFilters.time_zoneFieldErrorShowing }"
                          :data-vv-as="$t('marketing.workTimeTimezone')"
                          label="text"
                          track-by="text"
                          :empty="false"
                          :placeholder="$t('marketing.workTimeTimezoneMultiselectPlaceholder')"
                          :selectLabel="$t('general.multiSelectPressToSelect')"
                          :deselectLabel="$t('general.multiSelectDeselectLabel')"
                          :selectedLabel="$t('general.multiSelectSelected')"
                        >
                          <template v-slot:noOptions>
                            {{ $t('general.notFound') }}
                          </template>
                          <template v-slot:noResult>
                            {{ $t('general.notFound') }}
                          </template>
                          <!--                        <template slot="singleLabel" slot-scope="props"><span-->
                          <!--                          class="option__title">{{ cutOffLongTextForTimezoneMultiSelect(props.option.text) }}</span></template>-->
                        </multiselect>
                          </MultiselectWrapperForUseInFilters>

                        <span v-show="workTimeFilters.time_zoneFieldErrorShowing" class="help is-danger">
                          {{ $t('marketing.workTimeTimezoneNoSelectError') }}
                        </span>
                      </div>
                    </transition>
                  </div>
                </div>
              </transition>
            </div>
          </div>
      </div>
        <div v-if="isFiltersActive" class="">
              <button class="btn-outline-secondary btn btn-sm new-modern-style-btn-in-filters" @click="resetAllFilters">
                <i class="fa fa-close"></i>
                {{ $t('general.resetAllFilters') }}
              </button>
  </div>

    </div>


<!--    <div class="col-lg-12" v-if="isSummaryLoading">-->
<!--      &lt;!&ndash;      <div class="loader loader-backdrop loader-marketing" v-if="isSummaryLoading"></div>&ndash;&gt;-->
<!--      <div v-if="isSummaryLoading"><Loader-spinner centered></Loader-spinner></div>-->
<!--      &lt;!&ndash;      <div class="card">&ndash;&gt;-->
<!--      &lt;!&ndash;        <div class="card-header">&ndash;&gt;-->
<!--      &lt;!&ndash;          {{ $t('marketing.visitors') }}&ndash;&gt;-->
<!--      &lt;!&ndash;          <date-picker class="float-right" v-model="drPickerDate" :onChange="handleDateChangeForStat.bind(this)" />&ndash;&gt;-->
<!--      &lt;!&ndash;        </div>&ndash;&gt;-->
<!--      &lt;!&ndash;      </div>&ndash;&gt;-->
<!--    </div>-->
    <transition name="infoAboutWifiTarget" mode="out-in">
      <div class="col-lg-12" v-if="showInfoAboutWifiTarget || showMinutesLoadingInfoMsg">
        <Alert class="alert-info mb-h mt-h">
          <template #text>
            <div class="d-flex flex-column">
              <transition name="infoAboutWifiTarget" mode="out-in">
                <div v-if="showInfoAboutWifiTarget">
                  <span class="mr-h">{{ $t('marketing.infoAboutWifiTargetBegin') }}&nbsp;<b>{{cpesWithEnabledWiFiTarget ? cpesWithEnabledWiFiTarget : 0}}</b>&nbsp;{{getCPECountAsString(cpesWithEnabledWiFiTarget ? cpesWithEnabledWiFiTarget : 0)}}&nbsp;{{ $t('marketing.infoAboutWifiTargetEnd') }}</span>
                </div>
              </transition>
              <transition name="infoAboutWifiTarget" mode="out-in">
                <div v-if="showMinutesLoadingInfoMsg">
                  <span class="mr-h">{{ $t('marketing.avgLoadingDataTimeMsg') }}&nbsp;<b>{{avgMinutesLoadingDataString}}</b></span>
                </div>
              </transition>
            </div>

          </template>
        </Alert>
      </div>
    </transition>
    <div  class="col-lg-12" style="z-index: 9997;">
      <!--      <div class="loader loader-backdrop loader-marketing" v-if="isSummaryLoading"></div>-->
      <div v-if="isSummaryLoading"><Loader-spinner /></div>

      <div class="card">
        <div class="card-header">
          {{ $t('marketing.visitors') }}
          <!--          <date-picker class="float-right" v-model="drPickerDate" :onChange="handleDateChangeForStat.bind(this)" />-->
          <div class="float-right" style="min-width: 300px;">
            <div class="d-flex flex-row justify-content-end align-items-start">


                            <div class="filter mr-1">
<!--                <label>{{ $t('marketing.datePickerCaption') }}</label>-->
                <div class="input-group input-group" v-if="showDatePicker">
                  <date-picker
                    :show-error="isShowingDateRangeLimitExceededError"
                    enable-max-date-range-limit
                    class="date-picker-wrapper-in-visitors"
                    v-model="drPickerDate"
                    :onChange="handleDateChangeForStat.bind(this)"
                    @isDateRangeLimitEnabledAndExceeded="dateRangeLimitEnabledAndExceeded"
                  />
                </div>
                <div v-show="isShowingDateRangeLimitExceededError === true" class="mb-h mb-1">
                  <span
                    style="font-size: 0.8em;"
                    v-show="isShowingDateRangeLimitExceededError === true"
                    class="help is-danger"
                  >
                    {{ $t('general.dateRangeLimitExceededError') }}
                    {{ moment.duration(dateRangeLimitExceededErrorRangeLimit).humanize() }}
                  </span>
                </div>
              </div>
                          <div class="w-100 d-flex flex-column justify-content-end download-button-in-Visitors">
                    <download-extract-v2
                      :pdf-table-columns-show-headings="false"
                      :pdf-header-for-pdf="headerForDownloads"
                      :new-xls-header-for-excel="headerForDownloads"
                      newXlsAutoColumnsWidth
                      v-if="true"
                      class="float-rightn"
                      :data="formatToCSV()"
                      :data-for-excel="formatToExcel()"
                      excel-file-type="xlsx"
                      :excel-file-name="getFileNameForDownloadFiles(`${$t('downloadFilesNames.visitors')}`)"
                      :filename="getFileNameForDownloadFiles(`${$t('downloadFilesNames.visitors')}`)"
                      :downloads-formats-for-show="{
                        oldCsv: true,
                        newCsv: false,
                        oldXls: false,
                        newXls: true,
                        pdf: true
                      }"
                    />
            </div>


            </div>

          </div>

        </div>
        <div class="card-block">
          <!--          <div class="row">-->
          <!--            <div class="col-lg-12">-->
          <!--              <h4 class="text-center mb-2">-->
          <!--                {{ $t('marketing.report') }}: {{ (drPickerDate.startDate / 1000) | momentDate }} - -->
          <!--                {{ (drPickerDate.endDate / 1000) | momentDate }}-->
          <!--              </h4>-->
          <!--            </div>-->
          <!--          </div>-->
          <div class="row" v-if="false">
            <div class="col-lg-2 col-sm-4 filter-in-visitors-more-z-index-20">
              <div class="filter">
                <label>{{ $t('general.company') }}</label>
                <div class="input-group input-group filters__location">
                  <multiselect
                    v-tooltip.top-center="{ content: filters.company.name }"
                    class="filters-in-visitors-company-multiselect"
                    :multiple="false"
                    :allowEmpty="true"
                    v-model="filters.company"
                    :options="companys || []"
                    label="name"
                    trackBy="name"
                    @input="changeCompany"
                    :closeOnSelect="true"
                    :placeholder="$t('marketing.selectCompanyPlaceholder')"
                    :selectLabel="$t('general.multiSelectPressToSelect')"
                    :deselectLabel="$t('general.multiSelectDeselectLabel')"
                    :selectedLabel="$t('general.multiSelectSelected')"
                  >
                    <!--                    <template slot="singleLabel" slot-scope="props"><span-->
                    <!--                      class="option__title">{{ cutOffLongTextForCompanyMultiSelect(props.option.name) }}</span>-->
                    <!--                    </template>-->
                  </multiselect>
                </div>
              </div>
            </div>
            <div class="col-lg-2 col-sm-4  filter-in-visitors-more-z-index-15" v-if="filters.company === 'All companys'">
              <div class="filter">
                <label>{{ $t('marketing.location') }}</label>
                <div class="input-group input-group filters__location filters-in-visitors-location-multiselect-custom-z-index">
                  <multiselect
                    v-tooltip.top-center="{ content: filters.location.name,                           popperOptions: {
                            modifiers: {
                              preventOverflow: {
                                boundariesElement: 'window'
                              }
                            }
                          } }"
                    class="filters-in-visitors-location-multiselect"
                    :multiple="false"
                    :allowEmpty="true"
                    v-model="filters.location"
                    :options="locationsList || []"
                    label="name"
                    trackBy="name"
                    @input="changeLocation"
                    :closeOnSelect="true"
                    :placeholder="$t('marketing.selectLocationPlaceholder')"
                    :selectLabel="$t('general.multiSelectPressToSelect')"
                    :deselectLabel="$t('general.multiSelectDeselectLabel')"
                    :selectedLabel="$t('general.multiSelectSelected')"
                  >
                    <!--                    <template slot="singleLabel" slot-scope="props"><span-->
                    <!--                      class="option__title">{{ cutOffLongTextForLocationMultiSelect(props.option.name) }}</span>-->
                    <!--                    </template>-->
                  </multiselect>
                </div>
              </div>
              <div
              class="d-flex  align-items-center switcher-with-childs-for-use-in-filters-wrapper custom-position-for-with-childs-in-marketing">
              <div
                class="  d-flex align-items-end justify-content-start"
              >
                <Switch-component
                  :disabled="this.filters.location === 'All locations'"
                  v-model="filters.with_childs"
                  class="switcher-with-childs-for-use-in-filters"
                  @change="changeLocation"
                />
              </div>
                <div
                class="switcher-with-childs-for-use-in-filters-caption custom-position-for-with-childs-in-marketing">
                <small class=" custom-position-for-with-childs-in-marketing">{{ $t('general.withChild') }}</small>
              </div>

            </div>
            </div>
            <div class="col-lg-2 col-sm-4 filter-in-visitors-more-z-index-15" v-else>
              <div class="filter">
                <label>{{ $t('marketing.location') }}</label>
                <div class="input-group input-group filters__location filters-in-visitors-location-multiselect-custom-z-index">
                  <multiselect
                    v-tooltip.top-center="{ content: filters.location.name }"
                    class="filters-in-visitors-location-multiselect"
                    :multiple="false"
                    :allowEmpty="false"
                    v-model="filters.location"
                    :options="locationsObjbyIds(filters.company.locations)"
                    label="name"
                    trackBy="name"
                    @input="changeLocationIfCompanySelected"
                    :closeOnSelect="true"
                    :placeholder="$t('marketing.selectLocationPlaceholder')"
                    :selectLabel="$t('general.multiSelectPressToSelect')"
                    :deselectLabel="$t('general.multiSelectDeselectLabel')"
                    :selectedLabel="$t('general.multiSelectSelected')"
                  >
                    <!--                    <template slot="singleLabel" slot-scope="props"><span-->
                    <!--                      class="option__title">{{ cutOffLongTextForLocationMultiSelect(props.option.name) }}</span>-->
                    <!--                    </template>-->
                  </multiselect>
                </div>
              </div>
                            <div
              class=" d-flex  align-items-center switcher-with-childs-for-use-in-filters-wrapper custom-position-for-with-childs-in-marketing">
              <div
                class="  d-flex align-items-end justify-content-start"
              >
                <Switch-component
                  :disabled="this.filters.location === 'All locations'"
                  v-model="filters.with_childs"
                  class="switcher-with-childs-for-use-in-filters"
                  @change="changeLocationIfCompanySelected"
                />
              </div>
              <div
                class=" switcher-with-childs-for-use-in-filters-caption custom-position-for-with-childs-in-marketing">
                <small class=" custom-position-for-with-childs-in-marketing">{{ $t('general.withChild') }}</small>
              </div>
            </div>
            </div>


            <div class="col-lg-2 col-sm-4 filter-in-visitors-more-z-index-10">
              <div class="filter">
                <div class="d-flex justify-content-between">
                  <label>
                    {{ $t('marketing.radar') }}
                    <span v-if="!filters.cpes.length" class="text-muted">({{ $t('marketing.allCpes') }})</span>
                  </label>
                  <!--                  <div style="text-align: right">-->
                  <!--                    <a href="#" class="ml-q filter-in-visitors-cpes-show-description" @click.prevent="toggleShowingDescriptionInCpesFilter">-->
                  <!--                      {{ showDescriptionInCpesFilter ? $t('marketing.hideDescription') : $t('marketing.showDescription') }}-->
                  <!--                    </a>-->
                  <!--                  </div>-->
                </div>
                <!--                <label>-->
                <!--                  {{ $t('marketing.radar') }}-->
                <!--                  <span v-if="!filters.cpes.length" class="text-muted">({{ $t('marketing.allCpes') }})</span>-->
                <!--                </label>-->
                <!--                <div class="input-group input-group filters__location">-->
                <!--                  <multiselect-->
                <!--                    class="filters-in-visitors-cpes-multiselect"-->
                <!--                    :multiple="true"-->
                <!--                    v-model="filters.cpes"-->
                <!--                    :options="cpesListWithNames || []"-->
                <!--                    label="name"-->
                <!--                    trackBy="id"-->
                <!--                    @close="changeCPEs"-->
                <!--                    @input="changeCPEs"-->
                <!--                    :closeOnSelect="false"-->
                <!--                    :placeholder="$t('radar.selectPlaceholder')"-->
                <!--                    selectLabel="+"-->
                <!--                    deselectLabel="-"-->
                <!--                    :disabled="isDisableCpes"-->
                <!--                    :class="{ 'multiselect-disabled': isDisableCpes }"-->
                <!--                                        :selectLabel="$t('general.multiSelectPressToSelect')"-->
                <!--                    :deselectLabel="$t('general.multiSelectDeselectLabel')"-->
                <!--                    :selectedLabel="$t('general.multiSelectSelected')"-->
                <!--                  >-->
                <!--                    <template slot="option" slot-scope="props">-->
                <!--                      <div class="option__desc">-->
                <!--                        <span-->
                <!--                          class="badge-cpe badge-status ml-h tooltip-text"-->
                <!--                          :class="{-->
                <!--                            'badge-status__connected': props.option.connected,-->
                <!--                            'badge-status__disconnected': !props.option.connected-->
                <!--                          }"-->
                <!--                          v-tooltip.top-center="{-->
                <!--                            content: `<ul class='list-unstyled text-left m-0'>-->
                <!--                                          <li><small><b>id</b>: ${props.option.id}</small></li>-->
                <!--                                          <li><small><b>mac</b>: ${props.option.state.network.macaddr}</small></li>-->
                <!--                                          <li><small><b>ip</b>: ${props.option.state.network.ipaddr}</small></li>-->
                <!--                                        </ul>`-->
                <!--                          }"-->
                <!--                        >-->
                <!--&lt;!&ndash;                          <span v-if="props.option.name">{{ props.option.name }}</span>&ndash;&gt;-->
                <!--                          <template v-if="props.option.name">-->
                <!--                            <span v-if="props.option.name && !showDescriptionInCpesFilter">{{ props.option.name }}</span>-->
                <!--                            <template v-if="props.option.name && showDescriptionInCpesFilter">-->
                <!--                              <span v-if="!props.option.description">{{ props.option.name.split(':')[0] }}</span>-->
                <!--                              <span v-if="props.option.description" v-tooltip.right="{ content: props.option.description }">{{ props.option.name.split(':')[0]}}: {{ cutOffLongTextForCPEDescription(props.option.description)}}</span>-->
                <!--                            </template>-->
                <!--                          </template>-->

                <!--                          <span class="text-muted" v-else>{{ $t('misc.no_name') }}</span>-->
                <!--                        </span>-->
                <!--                      </div>-->
                <!--                    </template>-->
                <!--                  </multiselect>-->
                <!--                </div>-->
                <div class="input-group input-group filters__location">
                  <multiselect
                    class="filters-in-visitors-cpes-multiselect"
                    :multiple="true"
                    v-model="filters.cpes"
                    :options="cpesListWithNames || []"
                    label="name"
                    trackBy="id"
                    @close="changeCPEs"
                    @input="changeCPEs"
                    :closeOnSelect="false"
                    :placeholder="$t('radar.selectPlaceholder')"
                    selectLabel="+"
                    deselectLabel="-"
                    :disabled="isDisableCpes"
                    :class="{ 'multiselect-disabled': isDisableCpes }"
                    :selectLabel="$t('general.multiSelectPressToSelect')"
                    :deselectLabel="$t('general.multiSelectDeselectLabel')"
                    :selectedLabel="$t('general.multiSelectSelected')"
                  >
                    <template slot="option" slot-scope="props">
                      <div class="option__desc" v-if="!showDescriptionInCpesFilter">
                        <span
                          class="badge-cpe badge-status ml-h tooltip-text"
                          :class="{
                            'badge-status__connected': props.option.connected,
                            'badge-status__disconnected': !props.option.connected
                          }"
                          v-tooltip.top-center="{
                            content: `<ul class='list-unstyled text-left m-0'>
                                          <li><small><b>id</b>: ${props.option.id}</small></li>
                                          <li><small><b>mac</b>: ${props.option.state.network.macaddr}</small></li>
                                          <li><small><b>ip</b>: ${props.option.state.network.ipaddr}</small></li>
                                        </ul>`
                        ,
                          popperOptions: {
                            modifiers: {
                              preventOverflow: {
                                boundariesElement: 'window'
                              }
                            }
                          }
                        }"
                        >
                          <span v-if="props.option.name">{{ props.option.name }}</span>
                          <!--                          <template v-if="props.option.name">-->
                          <!--                            <span v-if="props.option.name && !showDescriptionInCpesFilter">{{ props.option.name }}</span>-->
                          <!--                            <template v-if="props.option.name && showDescriptionInCpesFilter">-->
                          <!--                              <span v-if="!props.option.description">{{ props.option.name.split(':')[0] }}</span>-->
                          <!--                              <span v-if="props.option.description" v-tooltip.right="{ content: props.option.description }">{{ props.option.name.split(':')[0]}}: {{ cutOffLongTextForCPEDescription(props.option.description)}}</span>-->
                          <!--                            </template>-->
                          <!--                          </template>-->

                          <span class="text-muted" v-else>{{ $t('misc.no_name') }}</span>
                        </span>
                      </div>
                      <div class="option__desc" v-if="showDescriptionInCpesFilter">
                        <span
                          class="badge-cpe badge-status ml-h tooltip-text"
                          :class="{
                            'badge-status__connected': props.option.connected,
                            'badge-status__disconnected': !props.option.connected
                          }"
                          v-tooltip="{
                            content: `<ul class='list-unstyled text-left m-0'>
                                          <li><small><b>id</b>: ${props.option.id}</small></li>
                                          <li><small><b>mac</b>: ${props.option.state.network.macaddr}</small></li>
                                          <li><small><b>ip</b>: ${props.option.state.network.ipaddr}</small></li>
                                          <li><small>${$t('general.description')}: <b></b> ${
                              props.option.description ? props.option.description : $t('general.no').toLowerCase()
                            }</small></li>
                                        </ul>`
                          }"
                        >
                          <!--                          <span v-if="props.option.name">{{ props.option.name }}</span>-->
                          <template v-if="props.option.name">
                            <span v-if="!props.option.description">
                              {{ props.option.name.split(charactersForCpeListSeparateNameFromDescription)[0] }}
                            </span>
                            <span v-if="props.option.description">
                              {{ props.option.name.split(charactersForCpeListSeparateNameFromDescription)[0] }}:
                              {{ cutOffLongTextForCPEDescription(props.option.description) }}
                            </span>
                          </template>

                          <span class="text-muted" v-else>{{ $t('misc.no_name') }}</span>
                        </span>
                      </div>
                    </template>
                  </multiselect>
                </div>
                <div class="d-flex justify-content-between">
                  <!--                  <label>-->
                  <!--                    {{ $t('marketing.radar') }}-->
                  <!--                    <span v-if="!filters.cpes.length" class="text-muted">({{ $t('marketing.allCpes') }})</span>-->
                  <!--                  </label>-->
                  <div class="w-100" style="text-align: right;">
                    <a
                      href="#"
                      class="ml-q filter-in-visitors-cpes-show-description"
                      @click.prevent="toggleShowingDescriptionInCpesFilter"
                    >
                      {{
                        showDescriptionInCpesFilter ? $t('marketing.hideDescription') : $t('marketing.showDescription')
                      }}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-2 col-sm-4">
              <div class="filter">
                <label>{{ $t('marketing.datePickerCaption') }}</label>
                <div class="input-group input-group"  v-if="showDatePicker">
                  <date-picker
                    :show-error="isShowingDateRangeLimitExceededError"
                    enable-max-date-range-limit
                    class="date-picker-wrapper-in-visitors"
                    v-model="drPickerDate"
                    :onChange="handleDateChangeForStat.bind(this)"
                    @isDateRangeLimitEnabledAndExceeded="dateRangeLimitEnabledAndExceeded"
                  />
                </div>
                <div v-show="isShowingDateRangeLimitExceededError === true" class="mb-h mb-1">
                  <span
                    style="font-size: 0.8em;"
                    v-show="isShowingDateRangeLimitExceededError === true"
                    class="help is-danger"
                  >
                    {{ $t('general.dateRangeLimitExceededError') }}
                    {{ moment.duration(dateRangeLimitExceededErrorRangeLimit).humanize() }}
                  </span>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-sm-4 submit d-flex justify-content-between">
              <button
                class="btn mr-1"
                :class="{ 'btn-success': !submitButton, 'btn-default': submitButton }"
                :disabled="submitButton || isShowingDateRangeLimitExceededError"
                type="button"
                @click="getSummary(this)"
              >
                {{ $t('marketing.submit') }}
              </button>
              <button
                :disabled="isShowingDateRangeLimitExceededError"
                v-tooltip="`${$t('marketing.createExportButtonInfo')}`"
                class="btn btn-outline-info btn-sm"
                type="button"
                @click="createSettingsForNewExportAndGoToExports"
              >
                {{ $t('marketing.createExportButtonCaption') }}
                <!--                <info class="" :content="$t('marketing.createExportButtonInfo')"/>-->
                <!--                <info class=""/>-->
              </button>
            </div>
            <!--            <div class="col-lg-2 col-sm-4 create-export-button text-right">-->
            <!--              <button-->
            <!--                class="btn btn-outline-info btn-sm"-->
            <!--                type="button"-->
            <!--                @click="createSettingsForNewExportAndGoToExports"-->
            <!--              >-->
            <!--                {{$t('marketing.createExportButtonCaption')}} <info class="" :content="$t('marketing.createExportButtonInfo')"/>-->
            <!--              </button>-->
            <!--            </div>-->
          </div>
          <!--          worktime filters-->
          <div class="row mt-q worktime-filters-block" v-if="false">
            <div class="col-lg-12 col-lg-12 mt-q">
              <div class="row">
                <div class="col-lg-12 col-sm-12 d-flex justify-content-start">
                  <div @click="toggleWorkTimeFiltersShowing" role="button">
                    <transition name="fadeforworktimefiltersinvisitors">
                      <i v-if="workTimeFilters.workTimeFiltersBlockShowing" class="icon-arrow-down"></i>
                      <i v-else class="icon-arrow-right"></i>
                    </transition>
                    &nbsp;
                    <span class="text-muted">{{ $t('marketing.workTimeBlockCaption') }} &nbsp;</span>
                    <info :content="$t('marketing.workTimeHint')" />
                    &nbsp;
                    <!--                    <transition name="fadeforworktimefiltersinvisitors">-->
                    <!--                      <i v-if="workTimeFilters.workTimeFiltersBlockShowing"-->
                    <!--                         class="icon-arrow-down"></i>-->
                    <!--                      <i v-else class="icon-arrow-left"></i>-->
                    <!--                    </transition>-->
                    &nbsp;
                    <transition name="fadeforworktimefiltersinvisitors">
                      <span
                        class="badge badge-light badge-success badge-wortime-wilter-enabled"
                        v-if="isWorkTimeFiltersEnabled"
                      >
                        {{ $t('marketing.workTimeFilterEnabled') }} &nbsp;
                      </span>
                    </transition>
                    &nbsp;
                  </div>
                </div>
              </div>
              <transition name="fadeforworktimefiltersinvisitors">
                <div class="row" v-show="workTimeFilters.workTimeFiltersBlockShowing">
                  <div class="col-lg-12 col-sm-12 d-flex justify-content-start align-items-start mt-h flex-wrap">
                    <!--    worktime enable switcher  -->
                    <div class="form-group mr-1 d-flex flex-column justify-content-end align-items-start">
                      <label class="mb-0" for="worktime-enable-switcher">{{ $t('general.enable') }}</label>
                      <div class="worktime-enable-switcher-wrapper-in-visitors-worktime-filters">
                        <Switch-component
                          class="worktime-enable-switcher-in-visitors-worktime-filters"
                          id="worktime-enable-switcher"
                          v-model="workTimeFilters.workTimeFiltersEnabled"
                          @change="handleWorktimeFilterEnableSwitcherSwitch"
                        />
                      </div>
                    </div>
                    <!--                    start-work-time start work time-->
                    <transition name="fadeforworktimefiltersinvisitors">
                      <div
                        v-if="isWorkTimeFiltersEnabled"
                        class="form-group mr-1 d-flex flex-column justify-content-end align-items-start worktime-filters-in-visitors-time-enter-fileds-wrappers"
                      >
                        <label class="mb-0" for="start-work-time">
                          {{ $t('marketing.workTimeStart') }}
                          <info :content="$t('marketing.workTimeStartHint')" />
                        </label>
                        <input
                          type="text"
                          name="start-work-time"
                          id="start-work-time"
                          :placeholder="$t('marketing.workTimeStartPlaceholder')"
                          class="form-control worktime-filters-in-visitors-time-enter-fileds"
                          v-validate="'required|hoursMinutesForVisitorsFilters'"
                          data-vv-validate-on="none"
                          :class="{ input: true, 'is-danger': errors.has('start-work-time') }"
                          v-model="workTimeFilters.startWorkTime"
                          :data-vv-as="$t('marketing.workTimeStart')"
                          @input="handleStartWorkTimeFieldInput"
                        />
                        <span v-show="errors.has('start-work-time')" class="help is-danger">
                          {{ errors.first('start-work-time') }}
                        </span>
                      </div>
                    </transition>
                    <transition name="fadeforworktimefiltersinvisitors">
                      <!--                    stop-work-time stop work time-->
                      <div
                        v-if="isWorkTimeFiltersEnabled"
                        class="form-group mr-1 d-flex flex-column justify-content-end align-items-start worktime-filters-in-visitors-time-enter-fileds-wrappers"
                      >
                        <label class="mb-0" for="stop-work-time">
                          {{ $t('marketing.workTimeStop') }}
                          <info :content="$t('marketing.workTimeStopHint')" />
                        </label>
                        <input
                          type="text"
                          name="stop-work-time"
                          id="stop-work-time"
                          :placeholder="$t('marketing.workTimeStopPlaceholder')"
                          class="form-control worktime-filters-in-visitors-time-enter-fileds"
                          v-validate="'required|hoursMinutesForVisitorsFilters'"
                          data-vv-validate-on="none"
                          :class="{ input: true, 'is-danger': errors.has('stop-work-time') }"
                          v-model="workTimeFilters.stopWorkTime"
                          :data-vv-as="$t('marketing.workTimeStop')"
                          @input="handleStopWorkTimeFieldInput"
                        />
                        <span v-show="errors.has('stop-work-time')" class="help is-danger">
                          {{ errors.first('stop-work-time') }}
                        </span>
                      </div>
                    </transition>
                    <transition name="fadeforworktimefiltersinvisitors">
                      <!--                    timezone-->
                      <div
                        v-if="isWorkTimeFiltersEnabled"
                        class="form-group mr-1 d-flex flex-column justify-content-end align-items-start worktime-filters-in-visitors-timezone-enter-multiselect-wrapper"
                      >
                        <label class="mb-0" for="tz">{{ $t('marketing.workTimeTimezone') }}</label>
                        <multiselect
                          v-tooltip.top-center="{ content: workTimeTimezoneSelectedInMultiselect.text,                           popperOptions: {
                            modifiers: {
                              preventOverflow: {
                                boundariesElement: 'window'
                              }
                            }
                          } }"
                          id="tz"
                          v-model="workTimeTimezoneSelectedInMultiselect"
                          @remove="handleTimezoneWorkTimeSelectorRemove"
                          @select="
                            (selectedOpt) => {
                              // тут делаем чтобы для москвы выбиралось Europe/Moscow
                              if (selectedOpt.value === 'Moscow Standard Time') {
                                workTimeFilters.time_zone = selectedOpt.utc[1];
                              } else {
                                workTimeFilters.time_zone = selectedOpt.utc[0];
                              }
                              this.handleTimezoneWorkTimeSelectorSelect();
                            }
                          "
                          :options="timeZonesOptions"
                          class="input worktime-filters-in-visitors-timezone-enter-multiselect"
                          :class="{ 'is-danger': workTimeFilters.time_zoneFieldErrorShowing }"
                          :data-vv-as="$t('marketing.workTimeTimezone')"
                          label="text"
                          track-by="text"
                          :empty="false"
                          :placeholder="$t('marketing.workTimeTimezoneMultiselectPlaceholder')"
                          :selectLabel="$t('general.multiSelectPressToSelect')"
                          :deselectLabel="$t('general.multiSelectDeselectLabel')"
                          :selectedLabel="$t('general.multiSelectSelected')"
                        >
                          <template v-slot:noOptions>
                            {{ $t('general.notFound') }}
                          </template>
                          <template v-slot:noResult>
                            {{ $t('general.notFound') }}
                          </template>
                          <!--                        <template slot="singleLabel" slot-scope="props"><span-->
                          <!--                          class="option__title">{{ cutOffLongTextForTimezoneMultiSelect(props.option.text) }}</span></template>-->
                        </multiselect>

                        <span v-show="workTimeFilters.time_zoneFieldErrorShowing" class="help is-danger">
                          {{ $t('marketing.workTimeTimezoneNoSelectError') }}
                        </span>
                      </div>
                    </transition>
                  </div>
                </div>
              </transition>
            </div>
          </div>



                    <div class="d-flex flex-row justify-content-end  w-100">
            <div class="float-right">
              <download-extract-v2
                class="float-right custom-download-button-size-in-AnalyticsVistiors "
                :pdf-header-for-pdf="headerForDownloads"
                :new-xls-header-for-excel="headerForDownloads"
                smallDownloadButton
                :showTooltipWhenSmallDownloadButton="false"
                newXlsAutoColumnsWidth
                v-if="true"
                :data="formatVisitsDataToCSV()"
                :data-for-excel="formatVisitsDataToExcel()"
                excel-file-type="xlsx"
                :excel-file-name="getFileNameForDownloadFiles(`${$t('downloadFilesNames.visitors')}-${$t('marketing.visits')}`)"
                :filename="getFileNameForDownloadFiles(`${$t('downloadFilesNames.visitors')}-${$t('marketing.visits')}`)"
                :downloads-formats-for-show="{
                        oldCsv: true,
                        newCsv: false,
                        oldXls: false,
                        newXls: true,
                        pdf: true
                      }"
              />
            </div>

          </div>
          <div class="row pl-1 d-flex pr-1">
            <div class="col-lg-3 col-md-3 d-flex pl-q pr-q">
              <stats-card :data="visits.all" :data-caption="$t('marketing.cVisits')" class="card-default">
                <template #icon><i class="icon-login" /></template>
              </stats-card>
            </div>

            <div class="col-lg-3 col-md-3 d-flex pl-q pr-q">
              <stats-card
                :data="momentFOnlyHoursAndMins(duration_avg.all)"
                :data-caption="$t('marketing.avgDwellTime')"
                class="card-returned"
              >
                <template #icon><i class="icon-clock"></i></template>
              </stats-card>
            </div>

            <div class="col-lg-3 col-md-3 d-flex pl-q pr-q">
              <stats-card
                :data="isNaN(visits.all / visitors.all) ? '-' : (visits.all / visitors.all).toFixed(2)"
                :data-caption="$t('marketing.avgVisitFreq')"
                class="card-returned"
              >
                <template #icon><i class="icon-fire"></i></template>
              </stats-card>
            </div>

            <div class="col-lg-3 col-md-3 d-flex pl-q pr-q">
              <stats-card :data="visitors.all" :data-caption="$t('marketing.uniqueVisitors')" class="card-unique">
                <template #icon><i class="icon-user"></i></template>
              </stats-card>
            </div>
          </div>
          <h4 class="text-center mt-h mb-2">{{ $t('marketing.visitors') }}</h4>
          <div class="d-flex flex-row justify-content-end  w-100">
            <div class="float-right">
              <download-extract-v2
                class="float-right custom-download-button-size-in-AnalyticsVistiors "
                :pdf-header-for-pdf="headerForDownloads"
                :new-xls-header-for-excel="headerForDownloads"
                smallDownloadButton
                :showTooltipWhenSmallDownloadButton="false"
                newXlsAutoColumnsWidth
                v-if="true"
                :data="formatVisitorsDataToCSV()"
                :data-for-excel="formatVisitorsDataToExcel()"
                excel-file-type="xlsx"
                :excel-file-name="getFileNameForDownloadFiles(`${$t('downloadFilesNames.visitors')}-${$t('marketing.visitors')}`)"
                :filename="getFileNameForDownloadFiles(`${$t('downloadFilesNames.visitors')}-${$t('marketing.visitors')}`)"
                :downloads-formats-for-show="{
                        oldCsv: true,
                        newCsv: false,
                        oldXls: false,
                        newXls: true,
                        pdf: true
                      }"
              />
            </div>

          </div>
          <div class="row px-1">
            <div class="card col-lg-6 col-md-6 px-q visitors-chart">
              <div class="p-1">
              <highcharts :options="RateHalf" class="rateHalf" id="rateHalf"></highcharts>
<!--              <SavePngButton class="save-icon color-brand save-img-button-in-AnalyticsVistiors" id="rateHalf" :name="getFileNameForDownloadFiles(`${$t('downloadFilesNames.analyticsVisitors')}-${$t('marketing.visitors')}`)" />-->
            </div>
              </div>
            <div class="col-lg-3 col-md-3 px-q d-flex">
              <stats-card class="card-new">
                <template #icon><i class="icon-user-follow text-new"></i></template>
                <template #body>
                  <div>
                    <div class="h4 mb-0 value--large">{{ visitors.new || '-' }}</div>
                    <small class="text-uppercase font-weight-bold text-muted">
                      <span
                        v-tooltip.top-center="{ content: 'Новые - впервые идентифицированы в данной локации' }"
                        class="tooltip-text"
                      >
                        {{ $t('marketing.new') }}
                      </span>
                    </small>
                    <div class="h4 mb-0 mt-1 value--large">
                      {{ momentFOnlyHoursAndMins(duration_avg.new) }}
                      <small class="text-danger"></small>
                    </div>
                    <small class="text-muted text-uppercase font-weight-bold">
                      {{ $t('marketing.avgDwellTime') }}
                    </small>
                  </div>
                </template>
              </stats-card>
            </div>

            <div class="col-lg-3 col-md-3 px-q d-flex">
              <stats-card class="card-returned">
                <template #icon><i class="icon-people text-returned"></i></template>
                <template #body>
                  <div>
                    <div class="h4 mb-0 value--large">{{ visitors.return || '-' }}</div>
                    <small class="text-uppercase font-weight-bold text-muted">
                      <span
                        v-tooltip.top-center="{
                          content: 'Повторные - уже были идентифицированы в данной локации один или несколько раз'
                        }"
                        class="tooltip-text"
                      >
                        {{ $t('marketing.repeat') }}
                      </span>
                    </small>
                    <div class="h4 mb-0 mt-1 value--large">{{ momentFOnlyHoursAndMins(duration_avg.return)}}</div>
                    <small class="text-muted text-uppercase font-weight-bold">{{ $t('marketing.avgDwellTime') }}</small>
                  </div>
                </template>
              </stats-card>
            </div>
          </div>



         <div class="card p-1">
          <div class="d-flex flex-row justify-content-end  w-100">
            <div class="float-right">
              <download-extract-v2
                class="float-right custom-download-button-size-in-AnalyticsVistiors "
                :pdf-header-for-pdf="headerForDownloads"
                :new-xls-header-for-excel="headerForDownloads"
                smallDownloadButton
                :showTooltipWhenSmallDownloadButton="false"
                newXlsAutoColumnsWidth
                v-if="true"
                :data="formatFootTrafficDataToCSV()"
                :data-for-excel="formatFootTrafficDataToExcel()"
                excel-file-type="xlsx"
                :excel-file-name="getFileNameForDownloadFiles(`${$t('downloadFilesNames.visitors')}-${$t('marketing.footTraffic')}`)"
                :filename="getFileNameForDownloadFiles(`${$t('downloadFilesNames.visitors')}-${$t('marketing.footTraffic')}`)"
                :downloads-formats-for-show="{
                        oldCsv: true,
                        newCsv: false,
                        oldXls: false,
                        newXls: true,
                        pdf: true
                      }"
              />
            </div>

          </div>

          <h4 class="text-center mt-3">{{ $t('marketing.footTraffic') }}</h4>

          <div class="row">
            <div class="col-lg-12">
              <div class="filters__multiple float-right">
                <div class="btn-group btn-group-sm float-left" data-toggle="button">
                  <label class="btn btn btn-outline-info" :class="{ active: filters.dateType == 'h' }">
                    <input type="radio" name="securityFilter" @click="setDateType('h')" />
                    {{ $t('general.hour') }}
                  </label>
                  <label class="btn btn btn-outline-info" :class="{ active: filters.dateType == 'd' }">
                    <input type="radio" name="securityFilter" @click="setDateType('d')" />
                    {{ $t('general.day') }}
                  </label>
                  <label class="btn btn btn-outline-info" :class="{ active: filters.dateType == 'm' }">
                    <input type="radio" name="securityFilter" @click="setDateType('m')" />
                    {{ $t('general.month') }}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-1">
            <div v-if="footTrafficChartShowing"  class="col-lg-12 col-md-12 pr-0">
              <!--              <div class="loader loader-backdrop loader-marketing" v-if="isVisitorsLoading"></div>-->
              <div v-if="isVisitorsLoading"><Loader-spinner></Loader-spinner></div>
              <highcharts :options="VisitorsChart" id="VisitorsChart"></highcharts>
<!--              <SavePngButton-->
<!--                class="save-icon color-brand save-img-button-in-AnalyticsVistiors"-->
<!--                id="VisitorsChart"-->
<!--                :name="getFileNameForDownloadFiles(`${$t('downloadFilesNames.analyticsVisitors')}-${$t('marketing.footTraffic')}`)"-->
<!--              />-->
            </div>
          </div>
        </div>

         <div class="card p-1">
          <h4 class="text-center mt-2 mb-2">{{ $t('marketing.repeatVisitors') }}</h4>
          <div class="row">
            <div class="col-lg-12">
              <highcharts :options="Returned" id="Returned"></highcharts>
<!--              <SavePngButton class="save-icon color-brand save-img-button-in-AnalyticsVistiors" id="Returned" :name="getFileNameForDownloadFiles(`${$t('downloadFilesNames.analyticsVisitors')}-${$t('marketing.repeatVisitors')}`)" />-->
            </div>
          </div>

                    <div class="d-flex flex-row justify-content-end  w-100 mb-q mt-1">
            <div class="float-right ">
              <download-extract-v2
                class="float-right custom-download-button-size-in-AnalyticsVistiors custom-download-button-size-in-AnalyticsVistiors-Returned-table "
                :pdf-header-for-pdf="headerForDownloads"
                :new-xls-header-for-excel="headerForDownloads"
                smallDownloadButton
                :showTooltipWhenSmallDownloadButton="false"
                newXlsAutoColumnsWidth
                v-if="true"
                :data="formatReturnedVisitorsDataToCSV()"
                :data-for-excel="formatReturnedVisitorsDataToExcel()"
                excel-file-type="xlsx"
                :excel-file-name="getFileNameForDownloadFiles(`${$t('downloadFilesNames.visitors')}-${$t('marketing.repeatVisitors')}`)"
                :filename="getFileNameForDownloadFiles(`${$t('downloadFilesNames.visitors')}-${$t('marketing.repeatVisitors')}`)"
                :downloads-formats-for-show="{
                        oldCsv: true,
                        newCsv: false,
                        oldXls: false,
                        newXls: true,
                        pdf: true
                      }"
              />
            </div>

          </div>
          <div class="row">
            <div class="col-lg-12">
              <div class="">
                <div class=" d-flex justify-content-between">
                  <span class=" ml-2"><h6>{{ $t('marketing.rVbyF') }}</h6></span>
                  <div class="filter-table">
                    <!--                    <select class="form-control form-control-sm">-->
                    <!--                      <option>{{ $t('marketing.byDefault') }}</option>-->
                    <!--                    </select>-->
                  </div>
                </div>
                <div class="card-body">
                  <table class="table">
                    <tr>
                      <th></th>
                      <th>{{ $t('marketing.cVisitsVisitors') }}</th>
                      <th>{{ $t('marketing.avgDwellTime') }}</th>
                    </tr>
                    <tr>
                      <td>
                        <b>{{ $t('marketing.visits1') }}</b>
                      </td>
                      <td>
                        {{ rate['1'] || '-' }}
                        <span class="text-success"></span>
                      </td>
                      <td>
                        {{ momentFOnlyHoursAndMins(duration['1']) || '-' }}
                        <span class="text-success"></span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>{{ $t('marketing.visits2') }}</b>
                      </td>
                      <td>
                        {{ rate['2'] || '-' }}
                        <span class="text-danger"></span>
                      </td>
                      <td>{{ momentFOnlyHoursAndMins(duration['2']) || '-' }}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>{{ $t('marketing.visits34') }}</b>
                      </td>
                      <td>
                        {{ rate['3'] || '-' }}
                        <span class="text-success"></span>
                      </td>
                      <td>{{ momentFOnlyHoursAndMins(duration['3']) || '-' }}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>{{ $t('marketing.visits59') }}</b>
                      </td>
                      <td>{{ rate['5'] || '-' }}</td>
                      <td>
                        {{ momentFOnlyHoursAndMins(duration['5']) || '-' }}
                        <span class="text-danger"></span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>{{ $t('marketing.visits10') }}</b>
                      </td>
                      <td>
                        {{ rate['10'] || '-' }}
                        <span class="text-danger"></span>
                      </td>
                      <td>
                        {{ momentFOnlyHoursAndMins(duration['10']) || '-' }}
                        <span class="text-danger"></span>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
           </div>
          <div class="card p-1">
          <h4 class="text-center mt-2 mb-3">{{ $t('marketing.vendors') }}</h4>
          <div class="d-flex flex-row justify-content-end  w-100 mb-q">
            <div class="float-right">
              <download-extract-v2
                class="float-right custom-download-button-size-in-AnalyticsVistiors "
                :pdf-header-for-pdf="headerForDownloads"
                :new-xls-header-for-excel="headerForDownloads"
                smallDownloadButton
                :showTooltipWhenSmallDownloadButton="false"
                newXlsAutoColumnsWidth
                v-if="true"
                :data="formatVendorsDataToCSV()"
                :data-for-excel="formatVendorsDataToExcel()"
                excel-file-type="xlsx"
                :excel-file-name="getFileNameForDownloadFiles(`${$t('downloadFilesNames.visitors')}-${$t('marketing.vendors')}`)"
                :filename="getFileNameForDownloadFiles(`${$t('downloadFilesNames.visitors')}-${$t('marketing.vendors')}`)"
                :downloads-formats-for-show="{
                        oldCsv: true,
                        newCsv: false,
                        oldXls: false,
                        newXls: true,
                        pdf: true
                      }"
              />
            </div>

          </div>

          <div class="row ">
            <div class="col-lg-6 col-md-6">
              <highcharts :options="vendorsChart" id="vendorsChart"></highcharts>
<!--              <SavePngButton class="save-icon color-brand save-img-button-in-AnalyticsVistiors" id="vendorsChart" :name="getFileNameForDownloadFiles(`${$t('downloadFilesNames.analyticsVisitors')}-${$t('marketing.vendors')}`)" />-->
            </div>

            <div class="col-lg-6 col-md-6">
              <table class="table vendor-table">
                <thead>
                  <tr>
                    <th class="vendor-cell">{{ $t('marketing.vendor') }}</th>
                    <th>{{ $t('marketing.count') }}</th>
                    <th>{{ $t('marketing.percent') }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="sortedVendors.length" v-for="vendor in sortedVendors">
                    <td class="vendor-cell">{{ vendor }}</td>
                    <td>{{ vendors[vendor] }}</td>
                    <td>{{ ((vendors[vendor] / allVendors) * 100).toFixed(1) }}%</td>
                  </tr>
                  <tr v-if="!sortedVendors.length" class="text-center">
                    <td colspan="3">{{ $t('aps.noDataToDisplay') }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Multiselect } from 'vue-multiselect';

import cpeService from '../../services/cpeService';
import DatePicker from '../../components/date-picker.vue';
import marketingService from '../../services/marketingService';
import SavePngButton from '../../components/save-png-button.vue';
import StatsCard from '../../components/Universal/stats-card.vue';
import helpers from '../../helpers';
import commonService from '../../services/commonService';
import Info from '../../components/Universal/info-icon.vue';
// import timeZones from '../Portal/timeZones';
import timeZones from '../Portal/timeZones.js';
import SwitchComponent from '../../components/Universal/Switch-component.vue';
import MultiselectWrapperWhenMultiselectForUseInFilters
  from '../../components/general/filters/MultiselectWrapperWhenMultiselectForUseInFilters.vue';
import MultiselectWrapperForUseInFilters
  from '../../components/general/filters/MultiselectWrapperForUseInFilters.vue';
import locationService from '../../services/locationService';
import SelectComponentV2 from '../../components/Universal/select/select-componentV2.vue';
import SelectComponentV3ForUseInLocationSelectors from '../../components/Universal/select/select-componentV3ForUseInLocationSelectors.vue';
import Alert from '@/components/Universal/alert/alert.vue';
import moment from "moment";

export default {
  name: 'Marketing',
  components: {
    Multiselect,
    SavePngButton,
    DatePicker,
    StatsCard,
    Info,
    SwitchComponent,
    MultiselectWrapperWhenMultiselectForUseInFilters,
    MultiselectWrapperForUseInFilters,
    SelectComponentV2,
    Alert,
    SelectComponentV3ForUseInLocationSelectors
  },
  data() {
    return {
      isCpesSelectorClosedWithoutChanges: false,
      isCpesSelectorOpened: false,
      timeOutForShowAvgMinutesLoadingData: undefined,
      showAvgMinutesLoadingData: false,
      doNotCallApiWhenDatesChangedInDatepicker: false,
      footTrafficChartShowing: true,
      delayTimerStartWorkTimeFieldInput: null,
      delayTimerStopWorkTimeFieldInput: null,
      delayTimerSelectCPEs: null,
      showLocationFilter: true,
      showDatePicker: true,
      locationInFiltersData: [],
      isShowingDateRangeLimitExceededError: false,
      dateRangeLimitExceededErrorRangeLimit: 0,
      charactersForCpeListSeparateNameFromDescription: ':::', // используем при создании списка CPE с описаниями  для мультиселекта
      showDescriptionInCpesFilter: false,
      workTimeTimezoneSelectedInMultiselect: '',
      workTimeFilters: {
        workTimeFiltersEnabled: false,
        workTimeFiltersBlockShowing: false,
        startWorkTime: '',
        stopWorkTime: '',
        time_zone: '',
        time_zoneFieldErrorShowing: false
      },
      visits: {},
      visitors: {},
      rate: {},
      duration: {},
      duration_avg: {},
      vendors: {},
      sortedVendors: [],
      allVendors: 0,
      isWlanID: true,
      submitButton: true,
      timeFormat: {
        h: 'h',
        min: 'min',
        sec: 'sec'
      },
      filters: {
        location: {},
        cpes: [],
        dateType: 'h',
        company: 'All companys',
        with_childs: true,
      },
      drPickerDate: {
        startDate: moment().startOf('day').subtract(1, 'days').valueOf(),
        endDate: moment().endOf('day').subtract(1, 'days').valueOf()
      },
      drPickerDateForHeaderInDownloads: {
        startDate: moment().startOf('day').subtract(1, 'days').valueOf(),
        endDate: moment().endOf('day').subtract(1, 'days').valueOf()
      },
      isDisableCpes: false,
      VisitorsChart: {
        chart: {
          type: 'column'
        },
        title: {
          text: ' '
        },
        subtitle: {
          text: ''
        },
        xAxis: {
          type: 'datetime',
          dateTimeLabelFormats: {
            month: '%e. %b',
            year: '%b'
          },
          title: {
            text: `${this.$t('marketing.date')}`
          },
          type: 'datetime'
        },
        yAxis: {
          min: 0,
          title: {
            text: `${this.$t('marketing.cVisits')}`
          }
        },
        tooltip: {
          headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
          pointFormat: '<tr><td>{series.name}: </td>' + '<td style="padding:0"><b>{point.y:.0f}</b></td></tr>',
          footerFormat: '</table>',
          shared: true,
          useHTML: true
        },
        plotOptions: {
          column: {
            stacking: 'normal'
          }
        },
        series: [
          {
            name: `${this.$t('marketing.repeat')}`,
            color: 'rgba(255, 143, 7, .8)',
            data: []
          },
          {
            name: `${this.$t('marketing.new')}`,
            color: '#81C784',
            data: []
          }
        ]
      },
      RateHalf: {
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: 0,
          plotShadow: false
        },
        title: {
          text: '',
          align: 'center',
          verticalAlign: 'middle',
          y: 40
        },
        tooltip: {
          pointFormat: '<b>{point.percentage:.1f}%</b>'
        },
        legend: {
          verticalAlign: 'top'
        },
        plotOptions: {
          pie: {
            dataLabels: {
              enabled: true,
              distance: -22,
              style: {
                fontWeight: 'bold',
                color: 'white',
                fontSize: '18px'
              },
              formatter() {
                return `${Math.round(this.percentage * 100) / 100}%`;
              }
            },
            showInLegend: true,
            startAngle: -90,
            endAngle: 90,
            center: ['50%', '85%'],
            size: '140%'
          }
        },
        series: [
          {
            type: 'pie',
            name: '',
            innerSize: '40%',
            data: [
              {
                name: `${this.$t('marketing.repeat')}`,
                y: 0,
                color: 'rgba(255, 143, 7, .8)'
              },
              {
                name: `${this.$t('marketing.new')}`,
                y: 0,
                color: '#81C784'
              }
            ]
          }
        ]
      },
      Returned: {
        chart: {
          type: 'bar'
        },
        title: {
          text: ''
        },
        subtitle: {
          text: ''
        },
        xAxis: {
          categories: [
            `${this.$t('marketing.visits1')}`,
            `${this.$t('marketing.visits2')}`,
            `${this.$t('marketing.visits34')}`,
            `${this.$t('marketing.visits59')}`,
            `${this.$t('marketing.visits10')}`
          ],
          title: {
            text: null
          }
        },
        yAxis: {
          min: 0,
          title: {
            text: `${this.$t('marketing.visitors')}`,
            align: 'middle'
          },
          labels: {
            overflow: 'justify'
          }
        },
        tooltip: {
          valueSuffix: ''
        },
        plotOptions: {
          bar: {
            dataLabels: {
              enabled: true
            }
          }
        },
        legend: {
          layout: 'vertical',
          align: 'right',
          verticalAlign: 'top',
          x: -40,
          y: 80,
          floating: true,
          borderWidth: 1,
          shadow: true,
          enabled: false,
        },
        credits: {
          enabled: false
        },
        series: [
          {
            name: `${this.$t('marketing.cVisitsVisitors')}`,
            color: 'rgba(255, 143, 7, 1)',
            data: [
              { y: 0, color: 'rgba(255, 143, 7, .4)' },
              { y: 0, color: 'rgba(255, 143, 7, .5)' },
              { y: 0, color: 'rgba(255, 143, 7, .6)' },
              { y: 0, color: 'rgba(255, 143, 7, .8)' },
              { y: 0, color: 'rgba(255, 143, 7, 1)' }
            ]
          }
        ]
      },
      vendorsChart: {
        chart: {
          type: 'pie'
        },
        title: {
          text: ''
        },
        subtitle: {},
        plotOptions: {
          pie: {
            size: '50%',
            allowPointSelect: true,
            cursor: 'pointer'
          },
          series: {
            dataLabels: {
              enabled: true,
              format: '{point.name}: {point.y:.1f}%'
            }
          }
        },

        tooltip: {
          headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
          // pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.1f}%</b> of total<br/>'
          pointFormat: `<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.1f}%</b> ${this.$t('general.ofTotal')}<br/>`
        },

        series: [
          {
            name: this.$t('marketing.vendors'),
            colorByPoint: true,
            data: []
          }
        ],
        drilldown: {
          series: []
        },
        lang: {
          drillUpText: this.$t('general.back')
        }
      }
    };
  },
  computed: {
    isSelectedDefaultLocationForRequests() {
      if (this.$store.getters.getDefaultLocationForRequests(this)) {
        return true;
      } else {
        return false;
      }
    },
    headerForDownloads() {
      try {
        const startDateTime = moment(this.drPickerDateForHeaderInDownloads.startDate).format('D MMM YYYY, H:mm');
        const endDateTime = moment(this.drPickerDateForHeaderInDownloads.endDate).format('D MMM YYYY, H:mm');
        const result = `${startDateTime} - ${endDateTime}`;
        return result;
      } catch (e) {
        console.log(e);
        return undefined
      }
    },
    userBaseLocation() {
      return this.$store.state.userData.base_location;
    },

    isWorkTimeFiltersEnabled() {
      return this.workTimeFilters.workTimeFiltersEnabled;
    },
    showMinutesLoadingInfoMsg(){
      if (this.avgMinutesLoadingDataString && this.isSummaryLoading && this.showAvgMinutesLoadingData){
        return true;
      } else {
        return false;
      }
    },
    avgMinutesLoadingDataString() {
      try {
        // неделя 30 секунд
        // 2 недели 1,5 минуты
        const timeRange = this.drPickerDate.endDate - this.drPickerDate.startDate;
        // console.log('timeRange', timeRange)
        const oneWeekMillisecond = 1000 * 60 * 60 * 24 * 7;
        const twoWeekMillisecond = oneWeekMillisecond * 2;
        // console.log('oneWeekMillisecond', oneWeekMillisecond)
        // console.log('twoWeekMillisecond', twoWeekMillisecond)
        const locale = (this.$i18n.locale === 'ru' || this.$i18n.locale === 'RU') ? 'RU' : 'EN';
        // console.log(twoWeekMillisecond < timeRange)
        if (locale === 'RU') {
          if (timeRange < oneWeekMillisecond) {
            return 'менее 30 секунд';
          } else if (oneWeekMillisecond <= timeRange && timeRange < twoWeekMillisecond) {
            return 'около 1.5 минут';
          } else if (twoWeekMillisecond <= timeRange) {
            return 'более 1.5 минут';
          }
        } else {
          if (timeRange < oneWeekMillisecond) {
            return 'is less than 30 seconds';
          } else if (oneWeekMillisecond <= timeRange && timeRange < twoWeekMillisecond) {
            return 'is about 1.5 minutes';
          } else if (twoWeekMillisecond <= timeRange) {
            return 'is more than 1.5 minutes';
          }
        }
      } catch (e) {
        console.log(e);
        return null;
      }
    },
    showInfoAboutWifiTarget() {
      try {
        if (!this.isDisableCpes) {
          return true
        } else {
          return false
        }
      } catch (e) {
        console.log(e)
        return false;
      }
    },
    cpesWithEnabledWiFiTarget() {
      try {
        if (!this.filters.cpes.length) {
          const cpes = this.cpesList;
          let counter = 0;
          for (const cpe of cpes) {
            if (cpe.config?.lbs_config?.enabled === true) {
              counter++
            }
          }
          return counter
        } else {
          const cpes = this.filters.cpes;
          let counter = 0;
          for (const cpe of cpes) {
            if (cpe.config?.lbs_config?.enabled === true) {
              counter++
            }
          }
          return counter
        }

      } catch (e) {
        console.log(e);
        return null;

      }
    },
    timeZonesOptions() {
      // опиции для выбора timezones
      return timeZones;
    },
    companys() {
      return this.$store.getters.getCompanyList;
    },
    companyList() {
      return this.$store.getters.companyListSorted;
    },
    tooltipForWorkTimeTimezoneSelectedInMultiselect() {
      return this.workTimeTimezoneSelectedInMultiselect?.text ?? '';
    },
    isFiltersActive(){
      return this.workTimeFilters.workTimeFiltersEnabled !== false ||
        this.workTimeFilters.startWorkTime !== '' || this.workTimeFilters.stopWorkTime !== '' ||
        this.workTimeFilters.time_zone !== '' ||
        (typeof this.filters.location === 'object' && Object.prototype.hasOwnProperty.call(this.filters.location, 'id') && this.filters.location.id !==  this.userBaseLocation)
        || this.filters.cpes.length !== 0 ||
        this.filters.company !== 'All companys'
    },
    isSummaryLoading() {
      return this.$store.state.loadingSummary;
    },
    isVisitorsLoading() {
      return this.$store.state.loadingVisitors;
    },
    // locationsList() {
    //   this.$store.state.locationsList.forEach((location) => {
    //     if (location.id === this.$store.state.baseLocation) {
    //       this.filters.location = location;
    //     }
    //   });
    //
    //   // return this.$store.state.locationsList;
    //   // return helpers.sortLocationsList(this.$store.state.locationsList);
    //   return this.$store.getters.locationsListSorted;
    // },
    locationsList() {
      // this.$store.state.locationsList.forEach((location) => {
      //   if (location.id === this.$store.state.baseLocation) {
      //     this.filters.location = location;
      //   }
      // });

      // return this.$store.state.locationsList;
      // return helpers.sortLocationsList(this.$store.state.locationsList);
      return this.$store.getters.locationsListSorted;
    },


    cpesList() {
      return this.$store.state.cpesList;
    },
    // cpesListWithNames() {
    //   const cpesWithName = [];
    //   const cpes = JSON.parse(JSON.stringify(this.cpesList));
    //   for (const cpeIndex in cpes) {
    //     if (cpes[cpeIndex].name && cpes[cpeIndex].name !== '') {
    //       cpesWithName.push(cpes[cpeIndex]);
    //     }
    //   }
    //   return cpesWithName;
    // }
    cpesListWithNames() {
      const cpesWithName = [];
      const cpes = JSON.parse(JSON.stringify(this.cpesList));
      for (const cpeIndex in cpes) {
        if (cpes[cpeIndex].name && cpes[cpeIndex].name !== '') {
          if (this.showDescriptionInCpesFilter === true) {
            if (cpes[cpeIndex].description !== '') {
              cpes[
                cpeIndex
              ].name = `${cpes[cpeIndex].name}${this.charactersForCpeListSeparateNameFromDescription} ${cpes[cpeIndex].description}`;
              cpesWithName.push(cpes[cpeIndex]);
            } else {
              cpes[cpeIndex].name = `${cpes[cpeIndex].name}`;
              cpesWithName.push(cpes[cpeIndex]);
            }
          } else {
            cpesWithName.push(cpes[cpeIndex]);
          }
        }
      }
      return cpesWithName;
    }
  },
  methods: {
    checkCpeForWiFiTargetEnabled(prop) {
      try {
        // console.log(prop);
        if (prop.option?.config?.lbs_config?.enabled) {
          return true;
        }
        return false;
      } catch (e) {
        console.log(e);
        return false;
      }
    },
    declOfNum(number, titles) {
      // для правильного окончания в слове точки доступа
      const cases = [2, 0, 1, 1, 1, 2];
      return titles[number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]];
    },
    getCPECountAsString(numberOfCPEs) {
      try {
        const locale = (this.$i18n.locale === 'ru' || this.$i18n.locale === 'RU') ? 'RU' : 'EN';
        if (locale === 'RU') {
          return this.declOfNum(numberOfCPEs, ['Точка Доступа', 'Точки Доступа', 'Точек Доступа']);
        }
        if (numberOfCPEs === 1) {
          return 'AP';
        }
        return 'APs';
      } catch (e) {
        console.log(e);
        return '';
      }
    },
    getFileNameForDownloadFiles(pageName) {
      return helpers.getFileNameForDownloadFiles(pageName)
    },
    checkNullUndefindedAndNanAndReturnZeroInThisCase(data) {
      try {
        if (data === undefined || Number.isNaN(data) || data === null || data === 'NaN') {
          return 0;
        } else {
          return data
        }
      } catch (e) {
        console.log(e)
        return data
      }
    },
    // makeDurationAvgForDownloads(durationAvg) {
    //   try {
    //     return this.$options.filters.moment(durationAvg);
    //   } catch (e) {
    //     console.log(e);
    //     return durationAvg;
    //   }
    // },
    makeDurationAvgForDownloads(durationAvg) {
      try {
        return this.momentFOnlyHoursAndMins(durationAvg);
      } catch (e) {
        console.log(e);
        return durationAvg;
      }
    },
    makeDateAsStringForDownloads(date, selectedDateTypeFilter) {
      try {
        let dateTime = date;
        // в зависимости от выбраного временного промежутка
        // по разному формируем даты для скачиваемых файлов
        if (selectedDateTypeFilter === 'h') {
          dateTime = moment.utc(date).format('DD.MM.YYYY HH:mm:ss');
        } else if (selectedDateTypeFilter === 'd') {
          dateTime = moment.utc(date).format('DD.MM.YYYY');
        } else if (selectedDateTypeFilter === 'm') {
          dateTime = moment.utc(date).format('MM.YYYY');
        }
        return dateTime;
      } catch (e) {
        console.log(e)
        return date;
      }
    },
    makeFootTrafficDataForExcel() {
      try {
        const result = {};
        const visits = [[`${this.$t('marketing.dateForDownloads')}`, `${this.$t('marketing.allVisitors')}`, `${this.$t('marketing.repeat')}`, `${this.$t('marketing.new')}`]]
        if (this.VisitorsChart.series[0].data.length) {
          for (const repeatVisitIndex in this.VisitorsChart.series[0].data) {
            const repeatVisit = this.VisitorsChart.series[0].data[repeatVisitIndex];
            const newVisit = this.VisitorsChart.series[1].data[repeatVisitIndex];
            const repeatVisitCounterForDate = this.checkNullUndefindedAndNanAndReturnZeroInThisCase(repeatVisit[1])
            const newVisitCounterForDate = this.checkNullUndefindedAndNanAndReturnZeroInThisCase(newVisit[1])
            const allVisitsCounterForDate = repeatVisitCounterForDate + newVisitCounterForDate;
            const dateTimeAsString = this.makeDateAsStringForDownloads(repeatVisit[0], this.filters.dateType)
            visits.push([dateTimeAsString, allVisitsCounterForDate, repeatVisitCounterForDate, newVisitCounterForDate])
          }
        } else {
          visits.push(['-', '-', '-', '-']);
        }

        result[`${this.$t('marketing.footTraffic')}`] = visits
        return result;
      } catch (e) {
        const result = {};
        result[`${this.$t('marketing.footTraffic')}`] = []
        return result
      }

    },
    makeFootTrafficDataForCSV() {
      try {
        const result = [];
        for (const repeatVisitIndex in this.VisitorsChart.series[0].data) {
          const repeatVisit = this.VisitorsChart.series[0].data[repeatVisitIndex];
          const newVisit = this.VisitorsChart.series[1].data[repeatVisitIndex];
          const repeatVisitCounterForDate = this.checkNullUndefindedAndNanAndReturnZeroInThisCase(repeatVisit[1])
          const newVisitCounterForDate = this.checkNullUndefindedAndNanAndReturnZeroInThisCase(newVisit[1])
          const allVisitsCounterForDate = repeatVisitCounterForDate + newVisitCounterForDate;
          const dateTimeAsString = this.makeDateAsStringForDownloads(repeatVisit[0], this.filters.dateType)
          const visitForDate = {};
          visitForDate[`${this.$t('marketing.dateForDownloads')}`] = dateTimeAsString
          visitForDate[`${this.$t('marketing.allVisitors')}`] = allVisitsCounterForDate
          visitForDate[`${this.$t('marketing.repeat')}`] = repeatVisitCounterForDate
          visitForDate[`${this.$t('marketing.new')}`] = newVisitCounterForDate
          result.push(visitForDate)
        }
        return result;
      } catch (e) {
        const result = [{}];
        return result
      }

    },
    makeVisitsDataForExcel() {
      try {
        const result = {};
        const visits = [
          [`${this.$t('marketing.cVisits')}`, this.visits.all || '-'],
          [`${this.$t('marketing.avgDwellTime')}`, this.makeDurationAvgForDownloads(this.duration_avg.all) || '-'],
          [`${this.$t('marketing.avgVisitFreq')}`, isNaN(this.visits.all / this.visitors.all) ? '-' : (this.visits.all / this.visitors.all).toFixed(2)],
          [`${this.$t('marketing.uniqueVisitors')}`, this.visitors.all || '-']
        ];
        result[`${this.$t('marketing.cVisits')}`] = visits;
        return result;
      } catch (e) {
        const result = {};
        result[`${this.$t('marketing.cVisits')}`] = [];
        return result
      }

    },
    makeVisitsDataForCSV() {
      try {
        let result = [];
        const visitsAll = {}
        visitsAll[`${this.$t('marketing.columnNameForDownloads')}`] = `${this.$t('marketing.cVisits')}`
        visitsAll[`${this.$t('marketing.columnValueForDownloads')}`] = this.visits.all
        const avgVisitsAllTime = {}
        avgVisitsAllTime[`${this.$t('marketing.columnNameForDownloads')}`] = `${this.$t('marketing.avgDwellTime')}`
        avgVisitsAllTime[`${this.$t('marketing.columnValueForDownloads')}`] = this.makeDurationAvgForDownloads(this.duration_avg.all)
        const avgVisitsFreq = {}
        avgVisitsFreq[`${this.$t('marketing.columnNameForDownloads')}`] = `${this.$t('marketing.avgVisitFreq')}`
        avgVisitsFreq[`${this.$t('marketing.columnValueForDownloads')}`] = isNaN(this.visits.all / this.visitors.all) ? '-' : (this.visits.all / this.visitors.all).toFixed(2)
        const uniqueVisitors = {}
        uniqueVisitors[`${this.$t('marketing.columnNameForDownloads')}`] = `${this.$t('marketing.uniqueVisitors')}`
        uniqueVisitors[`${this.$t('marketing.columnValueForDownloads')}`] = this.visitors.all
        if (visitsAll[`${this.$t('marketing.columnValueForDownloads')}`] === 0 &&
          avgVisitsAllTime[`${this.$t('marketing.columnValueForDownloads')}`] === undefined &&
          uniqueVisitors[`${this.$t('marketing.columnValueForDownloads')}`] === 0) {
          result = []
        } else {
          result.push(visitsAll, avgVisitsAllTime, avgVisitsFreq, uniqueVisitors)
        }
        return result;
      } catch (e) {
        console.log(e)
        const result = [{}];
        return result
      }

    },

    makeVisitorsDataForExcel() {
      try {
        const result = {};
        const visitors = [
          [`${this.$t('marketing.new')}`, this.visitors.new || '-', this.RateHalf.series[0].data[1].y ? `${this.RateHalf.series[0].data[1].y}%` : '-'],
          [`${this.$t('marketing.avgDwellTime')}`, this.makeDurationAvgForDownloads(this.duration_avg.new) || '-'],
          [`${this.$t('marketing.repeat')}`, this.visitors.return || '-', this.RateHalf.series[0].data[0].y ? `${this.RateHalf.series[0].data[0].y}%` : '-'],
          [`${this.$t('marketing.avgDwellTime')}`, this.makeDurationAvgForDownloads(this.duration_avg.return) || '-']
        ];
        result[`${this.$t('marketing.visitors')}`] = visitors;
        return result;
      } catch (e) {
        const result = {};
        result[`${this.$t('marketing.visitors')}`] = [];
        return result
      }
    },
    makeVisitorsDataForCSV() {
      try {
        let result = [];
        const newVisitors = {}
        newVisitors[`${this.$t('marketing.columnNameForDownloads')}`] = `${this.$t('marketing.new')}`;
        newVisitors[`${this.$t('marketing.columnValueForDownloads')}`] = this.visitors.new || '-';
        newVisitors[`${this.$t('marketing.columnPercentsForDownloads')}`] = this.RateHalf.series[0].data[1].y ? `${this.RateHalf.series[0].data[1].y}%` : '-';
        const avgForNewVisitors = {}
        avgForNewVisitors[`${this.$t('marketing.columnNameForDownloads')}`] = `${this.$t('marketing.avgDwellTime')}`;
        avgForNewVisitors[`${this.$t('marketing.columnValueForDownloads')}`] = this.makeDurationAvgForDownloads(this.duration_avg.new)
        avgForNewVisitors[`${this.$t('marketing.columnPercentsForDownloads')}`] = '';
        const repeatVisitors = {}
        repeatVisitors[`${this.$t('marketing.columnNameForDownloads')}`] = `${this.$t('marketing.repeat')}`;
        repeatVisitors[`${this.$t('marketing.columnValueForDownloads')}`] = this.visitors.return || '-';
        repeatVisitors[`${this.$t('marketing.columnPercentsForDownloads')}`] = this.RateHalf.series[0].data[0].y ? `${this.RateHalf.series[0].data[0].y}%` : '-';
        const avgForRepeatVisitors = {}
        avgForRepeatVisitors[`${this.$t('marketing.columnNameForDownloads')}`] = `${this.$t('marketing.avgDwellTime')}`;
        avgForRepeatVisitors[`${this.$t('marketing.columnValueForDownloads')}`] = this.makeDurationAvgForDownloads(this.duration_avg.return);
        avgForRepeatVisitors[`${this.$t('marketing.columnPercentsForDownloads')}`] = '';
        if (newVisitors[`${this.$t('marketing.columnValueForDownloads')}`] === '-' &&
          avgForNewVisitors[`${this.$t('marketing.columnValueForDownloads')}`] === undefined &&
          repeatVisitors[`${this.$t('marketing.columnValueForDownloads')}`] === '-' &&
          avgForRepeatVisitors[`${this.$t('marketing.columnValueForDownloads')}`] === undefined
        ) {
          result = [];
        } else {
          result.push(newVisitors, avgForNewVisitors, repeatVisitors, avgForRepeatVisitors)

        }
        return result;
      } catch (e) {
        console.log(e)
        const result = [{}];
        return result
      }
    },
    makeReturnedVisitorsDataForExcel(){
      try {
        const result = {};
        const returnedVisitors = [
          [`${this.$t('marketing.cVisits')}`, `${this.$t('marketing.cVisitsVisitors')}`, `${this.$t('marketing.avgDwellTime')}`],
          [`${this.$t('marketing.visits1')}`, this.rate['1'] || '-', this.momentFOnlyHoursAndMins(this.duration['1']) || '-'],
          [`${this.$t('marketing.visits2')}`, this.rate['2'] || '-', this.momentFOnlyHoursAndMins(this.duration['2']) || '-'],
          [`${this.$t('marketing.visits34')}`, this.rate['3'] || '-', this.momentFOnlyHoursAndMins(this.duration['3']) || '-'],
          [`${this.$t('marketing.visits59')}`, this.rate['5'] || '-', this.momentFOnlyHoursAndMins(this.duration['5']) || '-' ],
          [`${this.$t('marketing.visits10')}`, this.rate['10'] || '-', this.momentFOnlyHoursAndMins(this.duration['10']) || '-'],

        ];
        result[`${this.$t('marketing.repeatVisitors')}`] = returnedVisitors;
        return result;
      } catch (e) {
        const result = {};
        result[`${this.$t('marketing.repeatVisitors')}`] = [];
        return result
      }
    },
    makeReturnedVisitorsDataForCSV(){
      try {
        let result = [];
        const visits1 = {};
        visits1[`${this.$t('marketing.cVisits')}`] = `${this.$t('marketing.visits1')}`;
        visits1[`${this.$t('marketing.cVisitsVisitors')}`] = this.rate['1'] || '-';
        visits1[`${this.$t('marketing.avgDwellTime')}`] = this.momentFOnlyHoursAndMins(this.duration['1']) || '-';
        const visits2 = {};
        visits2[`${this.$t('marketing.cVisits')}`] = `${this.$t('marketing.visits2')}`;
        visits2[`${this.$t('marketing.cVisitsVisitors')}`] = this.rate['2'] || '-';
        visits2[`${this.$t('marketing.avgDwellTime')}`] = this.momentFOnlyHoursAndMins(this.duration['2']) || '-';
        const visits34 = {};
        visits34[`${this.$t('marketing.cVisits')}`] = `${this.$t('marketing.visits34')}`;
        visits34[`${this.$t('marketing.cVisitsVisitors')}`] = this.rate['3'] || '-';
        visits34[`${this.$t('marketing.avgDwellTime')}`] = this.momentFOnlyHoursAndMins(this.duration['3']) || '-';
        const visits59 = {};
        visits59[`${this.$t('marketing.cVisits')}`] = `${this.$t('marketing.visits59')}`;
        visits59[`${this.$t('marketing.cVisitsVisitors')}`] = this.rate['5'] || '-';
        visits59[`${this.$t('marketing.avgDwellTime')}`] = this.momentFOnlyHoursAndMins(this.duration['5']) || '-';
        const visits10 = {};
        visits10[`${this.$t('marketing.cVisits')}`] = `${this.$t('marketing.visits10')}`;
        visits10[`${this.$t('marketing.cVisitsVisitors')}`] = this.rate['10'] || '-';
        visits10[`${this.$t('marketing.avgDwellTime')}`] = this.momentFOnlyHoursAndMins(this.duration['10']) || '-'
        if (visits1[`${this.$t('marketing.cVisitsVisitors')}`] === '-' && visits2[`${this.$t('marketing.cVisitsVisitors')}`] === '-' &&
          visits34[`${this.$t('marketing.cVisitsVisitors')}`] === '-' && visits59[`${this.$t('marketing.cVisitsVisitors')}`] === '-' &&
          visits10[`${this.$t('marketing.cVisitsVisitors')}`] === '-') {
          result = []
        } else {
          result.push(visits1, visits2, visits34, visits59, visits10)
        }
        return result;
      } catch (e) {
        console.log(e)
        const result = [{}];
        return result
      }
    },
    makeVendorsDataForExcel() {
      try {
        const result = {};
        const vendors = [
          [`${this.$t('marketing.vendor')}`, `${this.$t('marketing.count')}`, `${this.$t('marketing.percent')}`],
        ];
        if (this.sortedVendors.length) {
          for (const vendor of this.sortedVendors) {
            vendors.push([vendor, this.vendors[vendor], `${((this.vendors[vendor] / this.allVendors) * 100).toFixed(1)}%`]);
          }
        } else {
          vendors.push(['-', '-', '-']);
        }

        result[`${this.$t('marketing.vendors')}`] = vendors;
        return result;
      } catch (e) {
        const result = {};
        result[`${this.$t('marketing.vendors')}`] = [];
        return result
      }
    },
    makeVendorsDataForCSV() {
      try {
        const result = [];
        for (const vendor of this.sortedVendors) {
          const vendorInfo = {};
          vendorInfo[`${this.$t('marketing.vendor')}`] = vendor;
          vendorInfo[`${this.$t('marketing.count')}`] = this.vendors[vendor];
          vendorInfo[`${this.$t('marketing.percent')}`] = `${((this.vendors[vendor] / this.allVendors) * 100).toFixed(1)}%`;

          result.push(vendorInfo);
        }
        return result;
      } catch (e) {
        const result = [{}];
        return result
      }
    },

    formatToExcel() {
      // return {'Название листа': [['колонка 1', 'колонка 2'], ['значение 1', 'знапчение 2']]};
      const result = {
        ...this.makeVisitsDataForExcel(),
        ...this.makeVisitorsDataForExcel(),
        ...this.makeFootTrafficDataForExcel(),
        ...this.makeReturnedVisitorsDataForExcel(),
        ...this.makeVendorsDataForExcel()
      };
      return result;
    },
    formatToCSV() {
      try {
        const columnOneName = 1;
        const columnTwoName = 2;
        const columnThreeName = 3;
        const columnFourName = 4;

        const result = [];
        const blankRow = {};
        blankRow[columnOneName] = '';
        blankRow[columnTwoName] = '';
        blankRow[columnThreeName] = '';
        blankRow[columnFourName] = '';




        // визиты
        const visitsPartName = {};
        visitsPartName[columnOneName] = `${this.$t('marketing.visits')}`;
        visitsPartName[columnTwoName] = '';
        visitsPartName[columnThreeName] = '';
        visitsPartName[columnFourName] = '';
        const visitsColumnsNames = {};
        visitsColumnsNames[columnOneName] = ''
        visitsColumnsNames[columnTwoName] = `${this.$t('marketing.columnValueForDownloads')}`;
        visitsColumnsNames[columnThreeName] = '';
        visitsColumnsNames[columnFourName] = '';

        result.push(visitsPartName, visitsColumnsNames);

        const visitsAll = {};
        visitsAll[columnOneName] = `${this.$t('marketing.cVisits')}`;
        visitsAll[columnTwoName] = this.visits.all !== 0 ? this.visits.all : '-';
        visitsAll[columnThreeName] = '';
        visitsAll[columnFourName] = '';
        const avgVisitsAllTime = {}
        avgVisitsAllTime[columnOneName] = `${this.$t('marketing.avgDwellTime')}`;
        avgVisitsAllTime[columnTwoName] = this.makeDurationAvgForDownloads(this.duration_avg.all) !== undefined ? this.makeDurationAvgForDownloads(this.duration_avg.all) : '-';
        avgVisitsAllTime[columnThreeName] = '';
        avgVisitsAllTime[columnFourName] = '';
        const avgVisitsFreq = {}
        avgVisitsFreq[columnOneName] = `${this.$t('marketing.avgVisitFreq')}`;
        avgVisitsFreq[columnTwoName] = isNaN(this.visits.all / this.visitors.all) ? '-' : (this.visits.all / this.visitors.all).toFixed(2);
        avgVisitsFreq[columnThreeName] = '';
        avgVisitsFreq[columnFourName] = '';
        const uniqueVisitors = {};
        uniqueVisitors[columnOneName] = `${this.$t('marketing.uniqueVisitors')}`;
        uniqueVisitors[columnTwoName] = this.visitors.all !== 0 ? this.visitors.all : '-';
        uniqueVisitors[columnThreeName] = '';
        uniqueVisitors[columnFourName] = '';
        result.push(visitsAll, avgVisitsAllTime, avgVisitsFreq, uniqueVisitors);

        result.push(blankRow);

        // посетители
        const visitorsPartName = {};
        visitorsPartName[columnOneName] = `${this.$t('marketing.visitors')}`;
        visitorsPartName[columnTwoName] = '';
        visitorsPartName[columnThreeName] = '';
        visitorsPartName[columnFourName] = '';
        const visitorsColumnsNames = {}
        visitorsColumnsNames[columnOneName] = '';
        visitorsColumnsNames[columnTwoName] = `${this.$t('marketing.columnValueForDownloads')}`;
        visitorsColumnsNames[columnThreeName] = `${this.$t('marketing.columnPercentsForDownloads')}`;
        visitorsColumnsNames[columnFourName] = '';

        result.push(visitorsPartName, visitorsColumnsNames);

        const newVisitors = {};
        newVisitors[columnOneName] = `${this.$t('marketing.new')}`;
        newVisitors[columnTwoName] = this.visitors.new || '-';
        newVisitors[columnThreeName] = this.RateHalf.series[0].data[1].y ? `${this.RateHalf.series[0].data[1].y}%` : '-';
        newVisitors[columnFourName] = '';
        const avgForNewVisitors = {};
        avgForNewVisitors[columnOneName] = `${this.$t('marketing.avgDwellTime')}`;
        avgForNewVisitors[columnTwoName] = this.makeDurationAvgForDownloads(this.duration_avg.new) !== undefined ? this.makeDurationAvgForDownloads(this.duration_avg.new) : '-'
        avgForNewVisitors[columnThreeName] = '';
        avgForNewVisitors[columnFourName] = '';
        const repeatVisitors = {};
        repeatVisitors[columnOneName] = `${this.$t('marketing.repeat')}`;
        repeatVisitors[columnTwoName] = this.visitors.return || '-';
        repeatVisitors[columnThreeName] = this.RateHalf.series[0].data[0].y ? `${this.RateHalf.series[0].data[0].y}%` : '-';
        repeatVisitors[columnFourName] = '';
        const avgForRepeatVisitors = {};
        avgForRepeatVisitors[columnOneName] = `${this.$t('marketing.avgDwellTime')}`;
        avgForRepeatVisitors[columnTwoName] = this.makeDurationAvgForDownloads(this.duration_avg.return) !== undefined ? this.makeDurationAvgForDownloads(this.duration_avg.return) : '-';
        avgForRepeatVisitors[columnThreeName] = '';
        avgForRepeatVisitors[columnFourName] = '';
        result.push(newVisitors, avgForNewVisitors, repeatVisitors, avgForRepeatVisitors)

        result.push(blankRow);


        // посещаемость
        const footTrafficPartName = {};
        footTrafficPartName[columnOneName] = `${this.$t('marketing.footTraffic')}`;
        footTrafficPartName[columnTwoName] = '';
        footTrafficPartName[columnThreeName] = '';
        footTrafficPartName[columnFourName] = '';
        const footTrafficColumnsNames = {}
        footTrafficColumnsNames[columnOneName] = `${this.$t('marketing.dateForDownloads')}`;
        footTrafficColumnsNames[columnTwoName] = `${this.$t('marketing.allVisitors')}`;
        footTrafficColumnsNames[columnThreeName] = `${this.$t('marketing.repeat')}`;
        footTrafficColumnsNames[columnFourName] = `${this.$t('marketing.new')}`;

        result.push(footTrafficPartName, footTrafficColumnsNames);

        if (this.VisitorsChart.series[0].data.length) {
          for (const repeatVisitIndex in this.VisitorsChart.series[0].data) {
            const repeatVisit = this.VisitorsChart.series[0].data[repeatVisitIndex];
            const newVisit = this.VisitorsChart.series[1].data[repeatVisitIndex];
            const repeatVisitCounterForDate = this.checkNullUndefindedAndNanAndReturnZeroInThisCase(repeatVisit[1]);
            const newVisitCounterForDate = this.checkNullUndefindedAndNanAndReturnZeroInThisCase(newVisit[1]);
            const allVisitsCounterForDate = repeatVisitCounterForDate + newVisitCounterForDate;
            const dateTimeAsString = this.makeDateAsStringForDownloads(repeatVisit[0], this.filters.dateType);
            const visitForDate = {};
            visitForDate[columnOneName] = dateTimeAsString;
            visitForDate[columnTwoName] = allVisitsCounterForDate;
            visitForDate[columnThreeName] = repeatVisitCounterForDate
            visitForDate[columnFourName] = newVisitCounterForDate;
            result.push(visitForDate)
          }
        } else {
          const visitForDate = {};
          visitForDate[columnOneName] = '-';
          visitForDate[columnTwoName] = '-';
          visitForDate[columnThreeName] = '-'
          visitForDate[columnFourName] = '-';
          result.push(visitForDate)
        }


        result.push(blankRow);


        // повторные
        const returnedVisitorsPartName = {};
        returnedVisitorsPartName[columnOneName] = `${this.$t('marketing.repeatVisitors')}`;
        returnedVisitorsPartName[columnTwoName] = '';
        returnedVisitorsPartName[columnThreeName] = '';
        returnedVisitorsPartName[columnFourName] = '';
        const returnedVisitorsColumnsNames = {}
        returnedVisitorsColumnsNames[columnOneName] = `${this.$t('marketing.cVisits')}`;
        returnedVisitorsColumnsNames[columnTwoName] = `${this.$t('marketing.cVisitsVisitors')}`;
        returnedVisitorsColumnsNames[columnThreeName] = `${this.$t('marketing.avgDwellTime')}`;
        returnedVisitorsColumnsNames[columnFourName] = '';

        result.push(returnedVisitorsPartName, returnedVisitorsColumnsNames)

        const visits1 = {};
        visits1[columnOneName] = `${this.$t('marketing.visits1')}`;
        visits1[columnTwoName] = this.rate['1'] || '-';
        visits1[columnThreeName] = this.momentFOnlyHoursAndMins(this.duration['1']) || '-';
        visits1[columnFourName] = '';
        const visits2 = {};
        visits2[columnOneName] = `${this.$t('marketing.visits2')}`;
        visits2[columnTwoName] = this.rate['2'] || '-';
        visits2[columnThreeName] = this.momentFOnlyHoursAndMins(this.duration['2']) || '-';
        visits2[columnFourName] = '';
        const visits34 = {};
        visits34[columnOneName] = `${this.$t('marketing.visits34')}`;
        visits34[columnTwoName] = this.rate['3'] || '-';
        visits34[columnThreeName] = this.momentFOnlyHoursAndMins(this.duration['3']) || '-';
        visits34[columnFourName] = '';
        const visits59 = {};
        visits59[columnOneName] = `${this.$t('marketing.visits59')}`;
        visits59[columnTwoName] = this.rate['5'] || '-';
        visits59[columnThreeName] = this.momentFOnlyHoursAndMins(this.duration['5']) || '-';
        visits59[columnFourName] = '';
        const visits10 = {};
        visits10[columnOneName] = `${this.$t('marketing.visits10')}`;
        visits10[columnTwoName] = this.rate['10'] || '-';
        visits10[columnThreeName] = this.momentFOnlyHoursAndMins(this.duration['10']) || '-';
        visits10[columnFourName] = '';
        result.push(visits1, visits2, visits34, visits59, visits10)

        result.push(blankRow);
        // вендоры
        const vendorsPartName = {};
        vendorsPartName[columnOneName] = `${this.$t('marketing.vendors')}`;
        vendorsPartName[columnTwoName] = '';
        vendorsPartName[columnThreeName] = '';
        vendorsPartName[columnFourName] = '';
        const vendorsColumnsNames = {}
        vendorsColumnsNames[columnOneName] = `${this.$t('marketing.vendor')}`;
        vendorsColumnsNames[columnTwoName] = `${this.$t('marketing.count')}`;
        vendorsColumnsNames[columnThreeName] = `${this.$t('marketing.percent')}`;
        vendorsColumnsNames[columnFourName] = '';

        result.push(vendorsPartName, vendorsColumnsNames)

        if (this.sortedVendors.length) {
          for (const vendor of this.sortedVendors) {
            const vendorInfo = {};
            vendorInfo[columnOneName] = vendor;
            vendorInfo[columnTwoName] = this.vendors[vendor];
            vendorInfo[columnThreeName] = `${((this.vendors[vendor] / this.allVendors) * 100).toFixed(1)}%`;
            vendorInfo[columnFourName] = '';
            result.push(vendorInfo);
          }
        } else {
          const vendorInfo = {};
          vendorInfo[columnOneName] = '-';
          vendorInfo[columnTwoName] = '-';
          vendorInfo[columnThreeName] = '-';
          vendorInfo[columnFourName] = '-';
          result.push(vendorInfo);
        }

        return result;
      } catch (e) {
        const result = [{}];
        return result
      }
    },
    formatFootTrafficDataToExcel() {
      return { ...this.makeFootTrafficDataForExcel(), };
    },
    formatFootTrafficDataToCSV() {
      return [...this.makeFootTrafficDataForCSV()];
    },
    formatVisitsDataToExcel() {
      return { ...this.makeVisitsDataForExcel() };
    },
    formatVisitsDataToCSV() {
      return [...this.makeVisitsDataForCSV()];
    },
    formatVisitorsDataToExcel() {
      return { ...this.makeVisitorsDataForExcel() };
    },
    formatVisitorsDataToCSV() {
      return [...this.makeVisitorsDataForCSV()];
    },
    formatReturnedVisitorsDataToExcel() {
      return { ...this.makeReturnedVisitorsDataForExcel() };
    },
    formatReturnedVisitorsDataToCSV() {
      return [...this.makeReturnedVisitorsDataForCSV()];
    },

    formatVendorsDataToExcel() {
      return { ...this.makeVendorsDataForExcel() };
    },
    formatVendorsDataToCSV() {
      return [...this.makeVendorsDataForCSV()];
    },

    dateRangeLimitEnabledAndExceeded(payload) {
      if (payload.dateRangeLimitEnabledAndExceeded === true) {
        this.isShowingDateRangeLimitExceededError = true;
        this.dateRangeLimitExceededErrorRangeLimit = payload.maxDateRangeLimit;
      } else {
        this.isShowingDateRangeLimitExceededError = false;
        this.dateRangeLimitExceededErrorRangeLimit = payload.maxDateRangeLimit;
      }
    },
    // resetAllFilters() {
    //   this.isShowingDateRangeLimitExceededError = false;
    //   this.locationInFiltersData = [];
    //   this.toggleShowLocationFilter();
    //   this.workTimeFilters = {
    //     workTimeFiltersEnabled: false,
    //     workTimeFiltersBlockShowing: false,
    //     startWorkTime: '',
    //     stopWorkTime: '',
    //     time_zone: '',
    //     time_zoneFieldErrorShowing: false
    //   };
    //   this.filters = {
    //     location: {},
    //     cpes: [],
    //     dateType: 'h',
    //     company: 'All companys',
    //     with_childs: false,
    //   };
    //   // защищаем от ненужного вызова API который происходит при смене даты в datepicker'e
    //   // по умолчанию
    //   this.doNotCallApiWhenDatesChangedInDatepicker = true;
    //   this.drPickerDate = {
    //     startDate: moment().startOf('day').subtract(1, 'days').valueOf(),
    //     endDate: moment().endOf('day').subtract(1, 'days').valueOf()
    //   };
    //   // а в следующем тике убираем защиту
    //   this.$nextTick(() => {
    //     this.doNotCallApiWhenDatesChangedInDatepicker = false;
    //     this.redrawDatePicker();
    //   });
    //   this.drPickerDateForHeaderInDownloads = {
    //     startDate: moment().startOf('day').subtract(1, 'days').valueOf(),
    //     endDate: moment().endOf('day').subtract(1, 'days').valueOf()
    //   };
    //   this.$store.state.locationsList.forEach((location) => {
    //     if (location.id === this.userBaseLocation) {
    //       this.filters.location = location;
    //       this.locationInFiltersData = location
    //       this.toggleShowLocationFilter();
    //     }
    //   });
    //   this.workTimeTimezoneSelectedInMultiselect = '';
    //   //  console.log(this.filters.location)
    //   marketingService.getCPEsbyLocationV2ForUseInMarketing(this);
    //   this.refreshAfterSelectInFilters();
    // },
    resetAllFilters() {
      this.isShowingDateRangeLimitExceededError = false;
     //   this.locationInFiltersData = [];
      // this.toggleShowLocationFilter();
      this.workTimeFilters = {
        workTimeFiltersEnabled: false,
        workTimeFiltersBlockShowing: false,
        startWorkTime: '',
        stopWorkTime: '',
        time_zone: '',
        time_zoneFieldErrorShowing: false
      };
      this.filters = {
        // location: {},
        cpes: [],
        dateType: 'h',
        company: 'All companys',
        // with_childs: false,
      };
      if (!this.isSelectedDefaultLocationForRequests) {
        this.filters.location = {};
        this.filters.with_childs = false;
        this.locationInFiltersData = [];
      } else {
        // console.log(this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationObject)
        this.filters.location = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationObject;
        // this.filters.location = this.$store.getters.getDefaultLocationForRequests(this)
        this.filters.with_childs = this.$store.getters.getDefaultWithChildsForRequests(this)
        this.locationInFiltersData = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationObject;
      }
      this.toggleShowLocationFilter();
      // защищаем от ненужного вызова API который происходит при смене даты в datepicker'e
      // по умолчанию
      this.doNotCallApiWhenDatesChangedInDatepicker = true;
      this.drPickerDate = {
        startDate: moment().startOf('day').subtract(1, 'days').valueOf(),
        endDate: moment().endOf('day').subtract(1, 'days').valueOf()
      };
      // а в следующем тике убираем защиту
      this.$nextTick(() => {
        this.doNotCallApiWhenDatesChangedInDatepicker = false;
        this.redrawDatePicker();
      });
      this.drPickerDateForHeaderInDownloads = {
        startDate: moment().startOf('day').subtract(1, 'days').valueOf(),
        endDate: moment().endOf('day').subtract(1, 'days').valueOf()
      };
      if (!this.isSelectedDefaultLocationForRequests) {
        this.$store.state.locationsList.forEach((location) => {
          if (location.id === this.userBaseLocation) {
            this.filters.location = location;
            this.locationInFiltersData = location
            this.toggleShowLocationFilter();
          }
        });
      }

      this.workTimeTimezoneSelectedInMultiselect = '';
      //  console.log(this.filters.location)
      marketingService.getCPEsbyLocationV2ForUseInMarketing(this);
      this.refreshAfterSelectInFilters();
    },
    cutOffLongTextForCPEDescription(text) {
      try {
        if (text.length > 60) {
          return `${text.slice(0, 60)}...`;
        }
        return text;
      } catch (e) {
        return text;
      }
    },
    refreshAfterSelectInFilters() {
       this.$nextTick(()=>{
         this.getSummary(this)
       })

    },
    inputStartWorkTimeFieldInput() {
      clearTimeout(this.delayTimerStartWorkTimeFieldInput);
      this.delayTimerStartWorkTimeFieldInput = setTimeout(() => {
        this.handleStartWorkTimeFieldInput();
        this.refreshAfterSelectInFilters();
      }, 1000);
    },
    inputStopWorkTimeFieldInput() {
      clearTimeout(this.delayTimerStopWorkTimeFieldInput);
      this.delayTimerStopWorkTimeFieldInput = setTimeout(() => {
        this.handleStopWorkTimeFieldInput();
        this.refreshAfterSelectInFilters();
      }, 1000);
    },
    selectLocationInFilters(location) {
      // console.log(location)
      if (!location) {
        this.filters.location = 'All locations';

      } else {
        // this.filters.location = location.id;
        this.filters.location = JSON.parse(JSON.stringify(location))
        // this.addLocationInPreviouslySelectedLocationsInFilter(location)
      }
      this.changeLocation();
      // this.getSummary(this)
      this.refreshAfterSelectInFilters();


    },
    selectWithChildsInFilters() {
       marketingService.getCPEsbyLocationV2ForUseInMarketing(this);
       this.$nextTick(()=>{
         this.getSummary(this)
       })
       // this.getSummary(this)
    },
    async requestLocations(searchQuery) {
      // return locationService.requestLocations(searchQuery);
      // return locationService.requestLocationsWithSort(searchQuery);
      return locationService.requestLocationsWithSortAndFilteringByChildsForLocationSelectInPages(this, searchQuery)
    },
    toggleShowFootTrafficChart() {
      this.footTrafficChartShowing = false;
      this.$nextTick(()=>{
        this.footTrafficChartShowing = true;
      })
    },
    toggleShowLocationFilter() {
      this.showLocationFilter = false;
      this.$nextTick(() => {
        this.showLocationFilter = true;
      })
    },
    redrawDatePicker(){
      this.showDatePicker= false;
      this.$nextTick(()=>{
        this.showDatePicker = true;
      })
    },
    toggleShowingDescriptionInCpesFilter() {
      this.showDescriptionInCpesFilter = !this.showDescriptionInCpesFilter;
    },
    loadWorkTimeFiltersSettingsFormLocalStorageOrCreateNew() {
      try {
        const settings = JSON.parse(localStorage.getItem('workTimeFiltersSettingsForVisitors'));
        if (settings === null) {
          this.saveWorkTimeFiltersSettingsToLocalStorage();
        } else {
          // console.log('here');
          this.workTimeFilters = JSON.parse(JSON.stringify(settings));
          this.workTimeTimezoneSelectedInMultiselect = this.extractTimeZoneObjectForDisplayInMultiselect(
            this.workTimeFilters.time_zone
          );
          this.workTimeFilters.time_zoneFieldErrorShowing = false; // чтобы не подгружалось состояние ошибок поля для выбора таймзоны
        }
      } catch (e) {
        console.log(e);
      }
    },
    saveWorkTimeFiltersSettingsToLocalStorage() {
      try {
        localStorage.setItem('workTimeFiltersSettingsForVisitors', JSON.stringify(this.workTimeFilters));
      } catch (e) {
        console.log(e);
      }
    },
    // extractTimeZoneObjectForDisplayInMultiselect(time_zone) {
    //   const timeZoneInBackendFormat = time_zone;
    //   // console.log(timeZoneInBackendFormat)
    //   if (timeZoneInBackendFormat === '') {
    //     return '';
    //   }
    //   const timeZonesList = this.timeZonesOptions;
    //   // console.log(timeZonesList)
    //   let timezoneObjectForDisplayInMultiselect = undefined;
    //   timezoneObjectForDisplayInMultiselect = timeZonesList.find((element) => {
    //     if (element.utc.includes(timeZoneInBackendFormat)) {
    //       return true;
    //     }
    //   });
    //   // console.log(timezoneObjectForDisplayInMultiselect)
    //   if (typeof timezoneObjectForDisplayInMultiselect === 'object') {
    //     return timezoneObjectForDisplayInMultiselect;
    //   }
    //
    //   return '';
    // },
    extractTimeZoneObjectForDisplayInMultiselect(timezone) {
      try {
        const timeZoneInBackendFormat = timezone;
        // console.log(timeZoneInBackendFormat)
        if (timeZoneInBackendFormat === '') {
          return '';
        }
        const timeZonesList = this.timeZonesOptions;
        // console.log(timeZonesList)
        let timezoneObjectForDisplayInMultiselect;
        timezoneObjectForDisplayInMultiselect = timeZonesList.find((element) => {
          if (element.utc.includes(timeZoneInBackendFormat)) {
            return true;
          }
        });
        // console.log(timezoneObjectForDisplayInMultiselect)
        if (typeof timezoneObjectForDisplayInMultiselect === 'object') {
          return timezoneObjectForDisplayInMultiselect;
        }
        return '';
      } catch (e) {
        return '';
      }
    },
    clearAllValidationErrors() {
      this.errors.clear();
      this.hideTimeZoneFieldError();
    },
    showTimeZoneFieldError() {
      this.workTimeFilters.time_zoneFieldErrorShowing = true;
    },
    hideTimeZoneFieldError() {
      this.workTimeFilters.time_zoneFieldErrorShowing = false;
    },
    cutOffLongTextForTimezoneMultiSelect(text) {
      try {
        if (text.length > 30) {
          return `${text.slice(0, 30)}...`;
        }
        return text;
      } catch (e) {
        return text;
      }
    },
    cutOffLongTextForCompanyMultiSelect(text) {
      try {
        if (text.length > 16) {
          return `${text.slice(0, 16)}...`;
        }
        return text;
      } catch (e) {
        return text;
      }
    },
    cutOffLongTextForLocationMultiSelect(text) {
      try {
        if (text.length > 17) {
          return `${text.slice(0, 17)}...`;
        }
        return text;
      } catch (e) {
        return text;
      }
    },
    handleTimezoneWorkTimeSelectorSelect() {
      // console.log('selected');
      this.hideTimeZoneFieldError();
      this.submitButton = false;
      this.saveWorkTimeFiltersSettingsToLocalStorage();
      this.refreshAfterSelectInFilters()
    },
    handleTimezoneWorkTimeSelectorRemove() {
      // console.log('selected');
      this.workTimeFilters.time_zone = '';
      this.hideTimeZoneFieldError();
      this.submitButton = false;
      this.saveWorkTimeFiltersSettingsToLocalStorage();
      this.refreshAfterSelectInFilters();
    },
    // handleWorktimeFilterEnableSwitcherSwitch() {
    //   this.clearAllValidationErrors();
    //   this.submitButton = false;
    //   this.saveWorkTimeFiltersSettingsToLocalStorage();
    // },

    handleWorktimeFilterEnableSwitcherSwitch() {
      this.clearAllValidationErrors();
      this.submitButton = false;
      this.saveWorkTimeFiltersSettingsToLocalStorage();
      if (this.workTimeFilters.startWorkTime !== '' || this.workTimeFilters.stopWorkTime || this.workTimeFilters.time_zone !==''){
        this.refreshAfterSelectInFilters();
      }
    },

    handleStartWorkTimeFieldInput() {
      // стираем оишибку валидатора для этого поля прои вводе
      if (Object.prototype.hasOwnProperty.call(this, 'errors')) {
        if (this.errors.has('start-work-time')) {
          this.errors.remove('start-work-time');
        }
      }
      this.submitButton = false;
      this.saveWorkTimeFiltersSettingsToLocalStorage();
    },
    handleStopWorkTimeFieldInput() {
      // стираем оишибку валидатора для этого поля прои вводе
      if (Object.prototype.hasOwnProperty.call(this, 'errors')) {
        if (this.errors.has('stop-work-time')) {
          this.errors.remove('stop-work-time');
        }
      }
      this.submitButton = false;
      this.saveWorkTimeFiltersSettingsToLocalStorage();
    },
    toggleWorkTimeFiltersShowing() {
      this.workTimeFilters.workTimeFiltersBlockShowing = !this.workTimeFilters.workTimeFiltersBlockShowing;
      this.saveWorkTimeFiltersSettingsToLocalStorage();
    },
    createSettingsForNewExportAndGoToExports() {
      // создаем объект с настройками для выгрузки который потом прикинем в раздел с Выгрузками
      const settingsForNewExport = {
        cpes: [],
        dateRange: {
          startDate: undefined,
          endDate: undefined
        },
        locationObj: undefined,
        locationId: undefined,
        company: undefined
      };

      // заполняем диапазон дат
      settingsForNewExport.dateRange.startDate = this.drPickerDate.startDate;
      settingsForNewExport.dateRange.endDate = this.drPickerDate.endDate;

      // заполняем компанию
      settingsForNewExport.company = JSON.parse(JSON.stringify(this.filters.company));

      // заполняем локацию
      settingsForNewExport.locationObj = JSON.parse(JSON.stringify(this.filters.location));
      settingsForNewExport.locationId = settingsForNewExport.locationObj.id;

      // заполняем выбраные CPE;
      if (this.filters.cpes.length === 0) {
        // если в фильтре точек ничего не выбрано - берем все точки из локации и добавляем
        for (const cpe of this.cpesList) {
          if (cpe.base_location === settingsForNewExport.locationId) {
            settingsForNewExport.cpes.push(cpe);
          }
        }
      } else {
        // а если в фильтре точек что-то выбрано просто берем эти выбраные точки
        settingsForNewExport.cpes = JSON.parse(JSON.stringify(this.filters.cpes));
      }

      // console.log(settingsForNewExport);
      this.$router.push({ name: 'Radar', params: { settingsForNewExport } });
    },
    // changeCompany() {
    //   if (this.updatedPageCompany !== 'All companys') {
    //     // console.log('change')
    //     let firstLocationIdForCompany = '';
    //     if (this.updatedPageCompany.hasOwnProperty('locations') && this.updatedPageCompany.locations.length > 0) {
    //       firstLocationIdForCompany = this.updatedPageCompany.locations[0];
    //       // console.log(firstLocationIdForCompany);
    //     }
    //     this.updatedPage.base_location = firstLocationIdForCompany;
    //   }
    // },
    locationsObjbyIds(ids) {
      return commonService.locationsObjbyIds(ids);
    },
    // getSummary() {
    //   this.submitButton = true;
    //   marketingService.getSummary(this);
    // },

    getSummary() {
      this.hideTimeZoneFieldError();
      if (this.workTimeFilters.workTimeFiltersEnabled === true) {
        this.$validator.validateAll().then(
          (result) => {
            if (result) {
              if (this.workTimeFilters.time_zone !== '') {
                this.submitButton = true;
                this.hideTimeZoneFieldError();
                // marketingService.getSummary(this);
                // marketingService.getSummary(this, '', true);
                marketingService.getSummaryV2ForUseInMarketing(this, '', true);
              } else {
                this.submitButton = true;
                this.showTimeZoneFieldError();
                this.workTimeFilters.workTimeFiltersBlockShowing = true;
              }
            } else {
              this.submitButton = true;
              if (this.workTimeFilters.time_zone === '') {
                this.showTimeZoneFieldError();
              }
              this.workTimeFilters.workTimeFiltersBlockShowing = true;
            }
          },
          () => {
            console.warn('Validation failed');
          }
        );
      } else {
        this.submitButton = true;
        // marketingService.getSummary(this);
        // marketingService.getSummary(this, '', true);
        marketingService.getSummaryV2ForUseInMarketing(this, '', true);

      }
    },
    changeLocation() {
      this.filters.company = 'All companys';
      // const selctedLocationId = this.filters.location.id;
      // for (const company of this.companyList){
      //   if (company.locationId === selctedLocationId){
      //     this.filters.company = JSON.parse(JSON.stringify(company));
      //   }
      // }
      this.filters.cpes = [];
      this.submitButton = false;
      marketingService.getCPEsbyLocationV2ForUseInMarketing(this);
    },
    changeLocationIfCompanySelected() {
      //  const selctedLocationId = this.filters.location.id;
      // for (const company of this.companyList){
      //   if (company.locationId === selctedLocationId){
      //     this.filters.company = JSON.parse(JSON.stringify(company));
      //   }
      // }
      this.filters.cpes = [];
      this.submitButton = false;
      marketingService.getCPEsbyLocationV2ForUseInMarketing(this);
      this.refreshAfterSelectInFilters();
    },
    changeCompany(val) {
      // console.log(val)
      if (val === null) {
        this.filters.company = 'All companys';
        this.filters.cpes = [];
        this.filters.location = '';
        this.submitButton = false;
        marketingService.getCPEsbyLocationV2ForUseInMarketing(this);
        this.refreshAfterSelectInFilters();
        return;
      }
      this.filters.cpes = [];

      let firstLocationIdForCompany = '';
      if (this.filters.company.hasOwnProperty('locations') && this.filters.company.locations.length > 0) {
        firstLocationIdForCompany = this.filters.company.locations[0];
        // console.log(firstLocationIdForCompany);
      }
      // this.updatedPage.base_location = firstLocationIdForCompany;
      this.filters.location = commonService.locationObjbyId(firstLocationIdForCompany);

      // const companyLocationId = this.filters.company.locationId;
      // console.log(companyLocationId)
      // let locationOfCompany = {};
      // for (const location of this.locationsList) {
      //   if (location.id === companyLocationId) {
      //     //  console.log(location)
      //     locationOfCompany = JSON.parse(JSON.stringify(location));
      //   }
      // }
      // this.filters.location = locationOfCompany;
      this.submitButton = false;
      marketingService.getCPEsbyLocationV2ForUseInMarketing(this);
      this.refreshAfterSelectInFilters();
    },
    // changeCPEs() {
    //   this.submitButton = false;
    //   try {
    //     if (Array.isArray(this.filters.cpes)) {
    //       for (const cpe of this.filters.cpes) {
    //         if (cpe.name.includes(this.charactersForCpeListSeparateNameFromDescription)) {
    //           cpe.name = cpe.name.split(this.charactersForCpeListSeparateNameFromDescription)[0]; // такое троеточие добвляется
    //           // когда создается список точек для отображения с описаниями
    //           // console.log('split!');
    //         }
    //       }
    //     }
    //   } catch (e) {}
    // },
    changeCPEsWithDelay() {
      clearTimeout(this.delayTimerSelectCPEs);
      this.delayTimerSelectCPEs = setTimeout(() => {
        this.changeCPEs();
      }, 1000);

    },
    // removeCPEsWithDelay() {
    //   if (!this.isCpesSelectorOpened) {
    //     clearTimeout(this.delayTimerSelectCPEs);
    //     this.delayTimerSelectCPEs = setTimeout(() => {
    //       this.changeCPEs();
    //     }, 1000);
    //
    //   }
    // },
    removeCPEsWithDelay() {
      if (!this.isCpesSelectorOpened) {
        clearTimeout(this.delayTimerSelectCPEs);
        // console.log(this.filters.cpes.length)
        if (this.filters.cpes.length !== 1 && this.filters.cpes.length !== 0) {
          // если в списке точек еще есть точки то ждем секунду, если юзер удалить несколько,
          // чтобы не делать несколько запросов после удаления каждой
          this.delayTimerSelectCPEs = setTimeout(() => {
            this.changeCPEs();
          }, 1000);
        } else {
          // если в списке точек осталась одна - сразу делаем запрос
          this.changeCPEs();
        }
      }
    },
    selectCpeInCpesSelector() {
      this.isCpesSelectorClosedWithoutChanges = false;
    },
    openCpesSelector() {
      this.isCpesSelectorOpened = true;
      this.isCpesSelectorClosedWithoutChanges = true;
    },
    removeAllFromCpesSelector() {
      this.filters.cpes = [];
      this.changeCPEs();
    },
    closeCpesSelector() {
      this.isCpesSelectorOpened = false;
      if (!this.isCpesSelectorClosedWithoutChanges) {
        this.changeCPEs();
      }
    },
    changeCPEs() {
      this.submitButton = false;
      try {
        if (Array.isArray(this.filters.cpes)) {
          for (const cpe of this.filters.cpes) {
            if (cpe.name.includes(this.charactersForCpeListSeparateNameFromDescription)) {
              cpe.name = cpe.name.split(this.charactersForCpeListSeparateNameFromDescription)[0]; // такое троеточие добвляется
              // когда создается список точек для отображения с описаниями
              // console.log('split!');
            }
          }
        }
        this.refreshAfterSelectInFilters();
      } catch (e) {}
    },
    momentF(value) {
      let formatted = '-';
      if (value) {
        const duration = moment.duration(value, 'seconds');
        formatted = duration.format(`h [${this.timeFormat.h}] m [${this.timeFormat.min}] s [${this.timeFormat.sec}]`);
        return formatted;
      }
    },
    momentFOnlyHoursAndMins(value) {
      // console.log(value)
      let formatted = '-';
      if (value) {
        let inputedSeconds = value;
        // если меньше минуты то округляем до минуты
        if (inputedSeconds < 60 && inputedSeconds > 0) {
          inputedSeconds = 60;
        }
        const duration = moment.duration(inputedSeconds, 'seconds');
        formatted = duration.format(`h [${this.timeFormat.h}] m [${this.timeFormat.min}]`);
        return formatted;
      }
    },
    // handleDateChangeForStat() {
    //   // marketingService.getSummary(this);
    //   if (!this.isShowingDateRangeLimitExceededError && !this.doNotCallApiWhenDatesChangedInDatepicker) {
    //     // marketingService.getSummary(this);
    //     // marketingService.getSummary(this, '', true);
    //     marketingService.getSummaryV2ForUseInMarketing(this, '', true);
    //   }
    // },
    handleDateChangeForStat() {
      // marketingService.getSummary(this);
      if (!this.isShowingDateRangeLimitExceededError && !this.doNotCallApiWhenDatesChangedInDatepicker) {
        this.drPickerDateForHeaderInDownloads.startDate = this.drPickerDate.startDate
        this.drPickerDateForHeaderInDownloads.endDate = this.drPickerDate.endDate
        // marketingService.getSummary(this);
        // marketingService.getSummary(this, '', true);
        marketingService.getSummaryV2ForUseInMarketing(this, '', true);
      }
    },
    setDateType(type) {
      this.filters.dateType = type;
      // marketingService.getSummary(this, 'visitors');
      // marketingService.getSummary(this, 'visitors', true)
      marketingService.getSummaryV2ForUseInMarketing(this, 'visitors', true)
    }
  },
  watch: {},
  filters: {
    moment(value) {
      let formatted = '-';
      if (value) {
        const duration = moment.duration(value, 'seconds');
        formatted = duration.format('hh:mm:ss');
        return formatted;
      }f
    },
    // moment(value) {
    //   let formatted = '-';
    //   if (value) {
    //     let inputedSeconds = value;
    //     if (inputedSeconds < 60) {
    //       inputedSeconds = 60;
    //     }
    //     let timeFormat = {
    //       h: 'h',
    //       min: 'min'
    //     }
    //     if (localStorage.getItem('NMS_LOCALE') === 'ru' || localStorage.getItem('NMS_LOCALE') === 'RU') {
    //       timeFormat = {
    //         h: 'ч.',
    //         min: 'мин.',
    //       }
    //     }
    //     const duration = moment.duration(inputedSeconds, 'seconds');
    //     formatted = duration.format(`h [${timeFormat.h}] m [${timeFormat.min}]`);
    //     return formatted;
    //   }
    // },
    momentDate(value) {
      if (value) {
        return moment(value * 1000).format(' D MMM YYYY, H:mm ');
      }
    }
  },
  // created() {
  //   this.loadWorkTimeFiltersSettingsFormLocalStorageOrCreateNew();
  //   this.$store.commit('setCpesList', false);
  //   this.timeFormat.h = this.$t('marketing.h');
  //   this.timeFormat.min = this.$t('marketing.min');
  //   this.timeFormat.sec = this.$t('marketing.sec');
  //   marketingService.getSummary(this);
  //   cpeService.getAllCompactCpes(this);
  // }

  created() {

    let inHeaderLocationSelectedApplying = true;
    if (this.$store.state.inHeaderLocationSelectModeEnabled &&
      inHeaderLocationSelectedApplying) {
      let withChildsForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.withChilds;
      let locationForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationID;
      if (!locationForFiltering || locationForFiltering === '') {
        // locationForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.userBaseLocation
        locationForFiltering = 'All locations';
      }
      // if (locationForFiltering === 'All locations') {
      //   this.$store.state.locationsList.forEach((location) => {
      //     if (location.id === this.userBaseLocation) {
      //       this.filters.location = location;
      //     }
      //   });
      //   console.log(this.filters.location)
      //   cpeService.getAllCompactCpes(this);
      // } else {
      //   this.filters.location = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationObject;
      //   marketingService.getCPEsbyLocationV2ForUseInMarketing(this)
      // }
      this.filters.with_childs = withChildsForFiltering;
      if (locationForFiltering === 'All locations') {
        this.$store.state.locationsList.forEach((location) => {
          if (location.id === this.userBaseLocation) {
            this.filters.location = location;
            this.locationInFiltersData = location
          }
        });
        // console.log(this.filters.location)
        // cpeService.getAllCompactCpes(this);

        marketingService.getCPEsbyLocationV2ForUseInMarketing(this)
      } else {
        this.filters.location = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationObject;
        this.locationInFiltersData = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationObject;
        marketingService.getCPEsbyLocationV2ForUseInMarketing(this)
      }

      // console.log('withChildsForFiltering', withChildsForFiltering)


    }


    this.loadWorkTimeFiltersSettingsFormLocalStorageOrCreateNew();
    this.$store.commit('setCpesList', false);
    this.timeFormat.h = this.$t('marketing.h');
    this.timeFormat.min = this.$t('marketing.min');
    this.timeFormat.sec = this.$t('marketing.sec');
    // marketingService.getSummary(this);
    // marketingService.getSummary(this,'', true);
    marketingService.getSummaryV2ForUseInMarketing(this,'', true);


  }
};
</script>

<style lang="scss" scoped>
  .visitors-chart{
    margin-top: 20px !important;
  }
.worktime-filters-in-visitors-timezone-enter-multiselect-wrapper {
  width: 300px;
}
@media (min-width: 0px) {
  .worktime-filters-block {
    position: relative !important;
    top: 30px !important;
  }
}

@media (min-width: 768px) {
  .worktime-filters-block {
    position: relative !important;
    top: 30px !important;
  }
}

@media (min-width: 992px) {
  .worktime-filters-block {
    position: inherit !important;
  }
}
.badge-wortime-wilter-enabled {
  font-size: 0.75em;
}
.traffic-profit {
  flex-grow: 1;
  margin-top: 10px;
  margin-bottom: 90px;
}

.category-profit {
  flex-grow: 1;
  margin-top: 20px;
}

.text-active {
  color: rgba(255, 143, 7, 1);
}

.text-loyalty {
  color: rgba(253, 216, 53, 1);
}

.text-returned {
  color: rgba(255, 143, 7, 0.8);
}

.text-new {
  color: #81c784;
}

.value {
  font-size: 1.7rem;

  &--big {
    font-size: 2.4rem;
  }

  &--large {
    font-size: 2rem;
  }

  &--title {
    font-size: 0.9rem;
  }
}

.card-new {
  border-top: 3px solid #81c784;
}

.card-returned {
  border-top: 3px solid rgba(255, 143, 7, 0.8);
}

.text-returned {
  color: rgba(255, 143, 7, 0.8);
}

.card-default {
  border-top: 4px solid rgba(183, 183, 183, 0.5);
}

.card-summary {
  margin-top: -30px;
}

.text-profit {
  font-size: 1.5rem;
}

/*.rateHalf {*/
/*  height: 300px;*/
/*}*/
.rateHalf {
  height: 230px;
}

.filter-table {
  width: 140px;
}

.text-muted {
  color: #94a0b2 !important;
}

.text-muted-light {
  color: #bcc5d0 !important;
}

.submit {
  & button {
    /*position: absolute;*/
    top: 28px;
    height: 42px;
    width: 150px;
  }
}

.create-export-button {
  & button {
    top: 28px;
    height: 40px;
  }
}

/*.loader-backdrop {*/
/*  &:after {*/
/*    position: absolute;*/
/*    width: 100px;*/
/*    height: 100px;*/
/*    top: 10%;*/
/*    border: 5px solid #e6e6e6;*/
/*    border-top-color: #1bb3a0;*/
/*  }*/
/*}*/

.vendor-table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;

  & tbody {
    display: block;
    width: 100%;
    overflow: auto;
    height: 400px;
  }

  & thead tr {
    display: block;
  }

  & th,
  & td {
    padding: 5px;
    text-align: left;
    width: 150px;
  }
  & .vendor-cell {
    width: 300px;
  }
}

.save-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
}
</style>

<style lang="scss">
  .badge-status__wifi-target-enabled:after {
  background-color: #4dbd74 !important;
}

.badge-status__wifi-target-disabled:after {
  background-color: #bcc5d0 !important;
}
    .infoAboutWifiTarget-enter-active,
.infoMessageForWPA3-leave-active {
  transition: opacity 0.3s;
}

.infoAboutWifiTarget-enter,
.infoAboutWifiTarget-leave-to {
  opacity: 0;
}
  .save-img-button-in-AnalyticsVistiors{
   position: relative;
    right: 22px !important;

  }
  .custom-download-button-size-in-AnalyticsVistiors button {
    position: absolute;
  padding: 0px;
  height: 20px;
  width: 20px;
}
.custom-download-button-size-in-AnalyticsVistiors {
  top: 0px;
  right: 10px;
}

/*.custom-download-button-size-in-AnalyticsVistiors-Returned-table{*/
/*  top: 15px !important;*/
/*  position: relative !important;*/
/*}*/

  .download-button-in-Visitors button{
        height: 40px !important;
    width: 100%;
  }
  .timezone-selector-in-AnalyticsVistiors .multiselect__tags .multiselect__placeholder{
    position: relative;
    top: -7px;
  }

  .timezone-selector-in-AnalyticsVistiors .multiselect__tags .multiselect__single{
    position: relative;
    top: -5px;
  }

  .input-group-for-location-select-in-AnalyticsVistiors--filters {


    position: relative;
    display: flex;

    align-items: stretch;
    width: 100%;
    max-width: 100%;
    z-index: 99997 !important;

  }

  .select-location-in-AnalyticsVistiors--filters .select__list-container {
    width: 400px;
  }



  .filters-in-visitors-location-multiselect-custom-z-index {
    z-index: 3 !important;
  }
  .custom-position-for-with-childs-in-marketing{
    /*position: relative;*/
    top: 5px;
    right: 0px;
    z-index: 1 !important;
  }
.loader-marketing:after {
  position: absolute;
  /*top: calc(50vh);*/
  top: 30px;
}
.fadeforworktimefiltersinvisitors-enter-active,
.fadeforworktimefiltersinvisitors-leave-active {
  transition: opacity 0.7s;
}
.fadeforworktimefiltersinvisitors-enter, .fadeforworktimefiltersinvisitors-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}
.worktime-enable-switcher-wrapper-in-visitors-worktime-filters {
  height: 39px !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100% !important;
}
.worktime-enable-switcher-in-visitors-worktime-filters label {
  height: 27px !important;
  margin: 0px !important;
}

.worktime-enable-switcher-in-visitors-worktime-filters span.switch-handle,
.worktime-enable-switcher-in-visitors-worktime-filters span.switch-label {
  height: 27px !important;
}
.worktime-filters-in-visitors-time-enter-fileds {
  height: 39px !important;
  padding-top: 5px !important;
}
.worktime-filters-in-visitors-time-enter-fileds-wrappers {
  width: 200px;
}

/*.worktime-filters-in-visitors-timezone-enter-multiselect {*/
/*  min-height: 27px !important;*/
/*}*/

.worktime-filters-in-visitors-timezone-enter-multiselect {
  width: 100%;
  height: 39px !important;
  text-align: center;
}

.worktime-filters-in-visitors-timezone-enter-multiselect .multiselect__tags {
  /*max-height: 90px !important;*/
  /*padding: 7px !important;*/
  /*overflow-y: auto;*/
  /*overflow-x: hidden;*/
  /*scrollbar-width: thin;*/
  padding-top: 6px !important;
}

.worktime-filters-in-visitors-timezone-enter-multiselect .multiselect__single {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.worktime-filters-in-visitors-timezone-enter-multiselect .multiselect__content-wrapper {
  overflow-x: hidden !important;
  width: 500px;
  text-align: left;
}
.date-picker-wrapper-in-visitors {
  height: 42px !important;
}

.date-picker-wrapper-in-visitors .mx-input-wrapper .mx-input {
  height: 42px !important;
  width: 100% !important;
}

.filters-in-visitors-company-multiselect {
  height: 43px !important;
}

.filters-in-visitors-company-multiselect .multiselect__tags {
  /*max-height: 90px !important;*/
  /*padding: 7px !important;*/
  /*overflow-y: auto;*/
  /*overflow-x: hidden;*/
  /*scrollbar-width: thin;*/
  /*padding-top: 6px !important;*/
  padding-bottom: 5px !important;
}

.filters-in-visitors-company-multiselect .multiselect__content-wrapper {
  overflow-x: hidden !important;
  width: 500px;
  text-align: left;
}

.filters-in-visitors-company-multiselect .multiselect__single {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.filters-in-visitors-location-multiselect {
  height: 43px !important;
}

.filters-in-visitors-location-multiselect .multiselect__tags {
  /*max-height: 90px !important;*/
  /*padding: 7px !important;*/
  /*overflow-y: auto;*/
  /*overflow-x: hidden;*/
  /*scrollbar-width: thin;*/
  /*padding-top: 9px !important;*/
  padding-bottom: 5px !important;
}

.filters-in-visitors-location-multiselect .multiselect__content-wrapper {
  overflow-x: hidden !important;
  width: 500px;
  text-align: left;
}
/*.filters-in-visitors-location-multiselect .multiselect__content-wrapper .multiselect__content li span{*/
/*  z-index: 50 !important;*/
/*}*/

.filters-in-visitors-location-multiselect .multiselect__single {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.filter-in-visitors-more-z-index {
  z-index: 10 !important;
}

.filter-in-visitors-more-z-index-10 {
  z-index: 10 !important;
}
.filter-in-visitors-more-z-index-15 {
  z-index: 15 !important;
}
.filter-in-visitors-more-z-index-20 {
  z-index: 20 !important;
}
.filters-in-visitors-cpes-multiselect .multiselect__content-wrapper {
  overflow-x: hidden !important;
  min-width: 500px;
  width: fit-content !important;
  text-align: left;
  /*    overflow-x: hidden;*/
  /*text-overflow: ellipsis;*/
}
.filter-in-visitors-cpes-show-description {
  font-size: 0.7em;
}
</style>
<!--background-image: url(https://leroy-moskva.ru/wp-content/uploads/2018/06/Lerua-Merlen-Lefortovo-shema.jpg);-->
<!--background-size: contain;-->
